import { ScaledNumber } from "scaled-number";
import useDefillamaApr from "./use-defillama-apr";

const POOL_ID = "cdd66c3a-3ab1-4d1d-9aac-2f7635f1c058";

const useLockerApr = (): ScaledNumber | null => {
  return useDefillamaApr(POOL_ID, "Locker");
};

export default useLockerApr;

import styled from "styled-components";

import Button from "../../components/Button";
import Radio, { RadioOption } from "../../components/Radio";
import { useState } from "react";
import Switch from "../../components/Switch";
import AssetSelector from "../../components/AssetSelector";

import { GlossFrame, SubCardFrame } from "../../styles/Frames";
import { Splitter } from "../../styles/utils";
import { useNavigate } from "react-router-dom";

import synthetix from "../../assets/supporters/synthetix.svg";
import discord from "../../assets/socials/discord-black.svg";
import { DISCORD } from "../../app/constants/urls";
import { MINT_PATH } from "../../app/constants/paths";
import { SUPPORTED_LEVERAGE } from "../../app/constants/supported-leverage";
import Seo from "../../components/Seo";

const LEVERAGE_OPTIONS = [2, 3, 4, 5, 10];

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const StyledHero = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

const Header = styled.h1`
  font-size: 6.8rem;
  font-weight: 300;
  max-width: 70rem;
  color: var(--main);
  text-align: left;

  @media (max-width: 900px) {
    font-size: 4.4rem;
    width: 100%;
  }
`;

const SubHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 5.6rem;

  @media (max-width: 900px) {
    margin-top: 2.8rem;
    margin-bottom: 7rem;
  }
`;

const SubHeader = styled.h2`
  font-size: 2.4rem;
  font-weight: 300;
  color: var(--sub);

  @media (max-width: 900px) {
    font-size: 2.4rem;
    width: 100%;
  }
`;

const UiOverlay = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const UiContainer = styled(SubCardFrame)`
  padding: 1.6rem 2.4rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2.4rem;
  justify-items: center;
  width: 32rem;
`;

const PoweredByLink = styled.a`
  height: 1.3rem;
  margin: auto;
  margin-top: 1.4rem;
  display: flex;
  align-items: center;
`;

const PoweredByText = styled.div`
  color: var(--sub);
  font-size: 1.1rem;
  font-weight: 400;
`;

const PoweredByLogo = styled.img`
  width: 8.5rem;
  margin-left: 0.5rem;
  opacity: 0.8;
`;

const ButtonContainer = styled.div`
  width: 100%;
`;

const HighlightButtonContainer = styled(GlossFrame)`
  display: flex;
  width: 45rem;
  height: 5.2rem;
  padding: 1.2rem;
  padding-left: 3rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 4.6rem;

  @media (max-width: 900px) {
    display: none;
  }
`;

const HighlightText = styled.p`
  color: #ed8cb2;
  font-size: 2.4rem;
  font-weight: 400;
`;

const Hero = () => {
  const navigate = useNavigate();
  const defaultDirection = "Long";
  const defaultAssetId = "ETH";
  const [assetId, setAssetId] = useState<string>(defaultAssetId);
  const leverageOptions: RadioOption[] = LEVERAGE_OPTIONS.map((value) => ({
    label: `${value}X`,
    value: value.toString(),
    comingSoon: !SUPPORTED_LEVERAGE[assetId].includes(value),
  }));
  const defaultLeverage = leverageOptions[0];
  const [leverage, setLeverage] = useState(defaultLeverage);
  const [direction, setDirection] = useState(defaultDirection);

  const touched =
    leverage !== defaultLeverage ||
    direction !== defaultDirection ||
    assetId !== defaultAssetId;

  return (
    <Container>
      <Seo
        title="TLX - Leveraged Tokens"
        description="TLX offers leveraged tokens for over 50 assets with leverage up to 20x powered by Synthetix. Discover the most streamlined trading experience on Optimism."
      />
      <StyledHero>
        <HeroContent>
          <Header>The simplest way to trade with leverage</Header>
          <SubHeaderContainer>
            <SubHeader>
              Leveraged tokens for 50+ assets with up to 20x leverage
            </SubHeader>
          </SubHeaderContainer>
          <HighlightButtonContainer>
            <HighlightText>Join the community</HighlightText>
            <Button
              primary
              icon={discord}
              action={() => {
                window.open(DISCORD, "_blank")?.focus();
              }}
            >
              Discord
            </Button>
          </HighlightButtonContainer>
        </HeroContent>
      </StyledHero>
      <UiOverlay>
        <UiContainer>
          <AssetSelector active={assetId} setActive={setAssetId} />
          <Splitter>
            <Switch
              switches={["Long", "Short"]}
              active={direction}
              setActive={(option: string) => setDirection(option)}
            />
          </Splitter>
          <Splitter>
            <Radio
              active={leverage}
              setActive={(option: RadioOption) => setLeverage(option)}
              options={leverageOptions}
            />
          </Splitter>
          <Splitter>
            <ButtonContainer>
              <Button
                primary={touched}
                wide
                action={() =>
                  navigate(
                    `/${MINT_PATH}?asset=${assetId}&direction=${direction.toLowerCase()}&leverage=${
                      leverage.value
                    }`
                  )
                }
              >
                Mint Leveraged Tokens
              </Button>
            </ButtonContainer>
            <PoweredByLink
              href="https://synthetix.io/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <PoweredByText>powered by</PoweredByText>
              <PoweredByLogo src={synthetix} alt="Synthetix" />
            </PoweredByLink>
          </Splitter>
        </UiContainer>
      </UiOverlay>
    </Container>
  );
};

export default Hero;

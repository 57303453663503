export const SITE = "https://tlx.fi/";
export const DISCORD = "https://discord.gg/kfp8bktXWN";
export const TWITTER = "https://twitter.com/tlx_fi";
export const GITHUB = "https://github.com/TLX-Protocol";
export const MIRROR = "https://mirror.xyz/tlx.eth";
export const SNAPSHOT = null;
export const DOCS = "https://docs.tlx.fi/";
export const FEEDBACK = "https://tlx.featurebase.app/";
export const STATS = "https://tlxstats.com/";
export const GENESIS_LOCKER_DOCS =
  "https://docs.tlx.fi/basics/tokenomics/understanding-usdtlx#genesis-locking";
export const STAKE_DOCS =
  "https://docs.tlx.fi/basics/tokenomics/understanding-usdtlx#staking";
export const BONDING_DOCS =
  "https://docs.tlx.fi/basics/tokenomics/bonding-for-usdtlx";
export const AIRDROP_INFO =
  "https://mirror.xyz/tlx.eth/hIztfICxUnjPXeG6TCl7rClR_H9UdSxpjEpFSQv5OPc";
export const REFERRALS_DOCS = "https://docs.tlx.fi/community/referral-program";
export const TLX_VELODROME_LP =
  "https://velodrome.finance/deposit?token0=0x4200000000000000000000000000000000000006&token1=0xD9cC3D70E730503E7f28c1B407389198c4B75FA2&type=-1";
export const DEFILLAMA_API_DOCS = "https://defillama.com/docs/api";
export const TLX_YIELD_SERVER =
  "https://github.com/DefiLlama/yield-server/blob/master/src/adaptors/tlx-finance/index.ts";
export const VELODROME_YIELD_SERVER =
  "https://github.com/DefiLlama/yield-server/blob/master/src/adaptors/velodrome-v2/index.js";

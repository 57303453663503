import styled from "styled-components";

import AssetSelector from "../../components/AssetSelector";
import { useState } from "react";
import Switch from "../../components/Switch";

import Radio, { RadioOption } from "../../components/Radio";
import Input from "../../components/Input";
import Chart from "../../components/Chart";
import Button from "../../components/Button";
import useLeveragedTokenData from "../../app/web3/views/use-leveraged-token-data";
import { getAssetData } from "../../app/helpers/get-asset-data";
import { CardFrame, SubCardFrame } from "../../styles/Frames";
import { Splitter } from "../../styles/utils";
import useApprovedAmount from "../../app/web3/views/use-approved-amount";
import useLeveragedToken from "../../app/web3/contracts/use-leveraged-token";
import useToken from "../../app/web3/contracts/use-token";
import InputHeader from "../../components/InputHeader";
import getRoundedScaledNumber from "../../app/helpers/get-rounded-scaled-number";
import { useDispatch } from "react-redux";
import { setError } from "../../state/uiSlice";
import getSlippage from "../../app/helpers/get-slippage";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PORTFOLIO_PATH } from "../../app/constants/paths";
import getLeveragedTokenSymbol from "../../app/helpers/get-leveraged-token-symbol";
import useReferralCodeStorage from "../../app/hooks/use-referral-code-storage";
import useReferralCode from "../../app/web3/views/use-referral-code";
import useReferrals from "../../app/web3/contracts/use-referrals";
import { formatBytes32String } from "ethers/lib/utils";
import useLeveragedTokenChart from "../../app/hooks/use-leveraged-token-chart";
import LoadingSpinner from "../../components/LoadingSpinner";
import useSupportedZapTokens from "../../app/web3/views/use-supported-zap-tokens";
import TOKEN_METADATA, { getTokenId } from "../../app/data/token-metadata";
import useTokenBalance from "../../app/web3/views/use-token-balance";
import { SUSD, ZAP_SWAP_ADDRESS } from "../../app/constants/addresses";
import useZap from "../../app/web3/contracts/use-zap";
import MintInfoSection from "./MintInfoSection";
import useMintAmountOut from "../../app/web3/views/use-mint-amount-out";
import useTokenPrice from "../../app/web3/views/use-token-price";
import InfoLine from "../../components/InfoLine";
import round from "../../app/helpers/round";
import {
  PRICE_IMPACT_GAIN_THRESHOLD,
  PRICE_IMPACT_WARNING_THRESHOLD,
} from "../../app/constants/config";
import { SUPPORTED_LEVERAGE } from "../../app/constants/supported-leverage";
import Seo from "../../components/Seo";
import LeveragedTokenAmount from "./LeveragedTokenAmount";
import MintingDisabled from "../../components/MintingDisabled";

const StyledMintPage = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  padding: 4rem;

  @media (max-width: 900px) {
    padding: 2rem;
  }
`;

const Container = styled(CardFrame)`
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4rem;
  max-width: 107rem;

  @media (max-width: 900px) {
    padding: 0;
    background: none;
    border: none;
  }
`;

const Sections = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-gap: 2.4rem;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const Section = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2.4rem;
  height: 100%;
  grid-template-rows: 1fr min-content;
`;

const ChartContainer = styled(SubCardFrame)`
  position: relative;
  flex: 1;

  @media (max-width: 900px) {
    height: 20rem;
  }
`;

const MintPage = () => {
  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const leveragedTokenDataSource = useLeveragedTokenData();
  const leveragedTokenData = leveragedTokenDataSource?.filter(
    (data) => data.isActive
  );
  const [searchParams] = useSearchParams();
  const { code } = useReferralCodeStorage();
  const referralCode = useReferralCode();
  const referralContract = useReferrals();
  const zapAssets = useSupportedZapTokens();
  const zap = useZap();
  const susdPrice = useTokenPrice(SUSD);

  // Params
  const hasClaimedReferralCode = referralCode !== null && referralCode !== "";
  const hasCodeToClaim = !!code;
  const initialAssetId = searchParams.get("asset") || "ETH";
  const initialIsLong = searchParams.get("direction")
    ? searchParams.get("direction") === "long"
    : true;
  const initialLeverage = searchParams.get("leverage") || "2";

  // State
  const [mintToken, setMintToken] = useState<string>("susd");
  const [assetId, setAssetId] = useState<string>(initialAssetId);
  const [isLong, setIsLong] = useState<boolean>(initialIsLong);
  const [collateral, setCollateral] = useState<string>("");
  const defaultLeverage = {
    value: initialLeverage,
    label: `${initialLeverage}x`,
  };
  const [leverage, setLeverage] = useState<RadioOption>(defaultLeverage);

  // Data
  const mintTokenData = TOKEN_METADATA[mintToken];
  if (!mintTokenData) throw new Error("Invalid mint token");
  const mintTokenPrice = useTokenPrice(mintTokenData.address);
  const mintTokenBalance = useTokenBalance(mintTokenData.address);
  const mintTokenContract = useToken(mintTokenData.address);
  const data = leveragedTokenData?.find(
    (lt) =>
      lt.targetAsset === assetId &&
      lt.isLong === isLong &&
      lt.targetLeverage.toCryptoString() === leverage.value
  );
  const chart = useLeveragedTokenChart(data?.addr);
  const leveragedToken = useLeveragedToken(data?.addr || null);
  const mintWithZap = mintToken !== "susd";
  const approvedAmount = useApprovedAmount(
    mintTokenData.address,
    mintWithZap ? ZAP_SWAP_ADDRESS : leveragedToken?.address
  );
  const leverageOptions = SUPPORTED_LEVERAGE[assetId]?.map((value) => {
    return {
      value: value.toString(),
      label: `${value}x`,
    };
  });
  const assetOptions = leveragedTokenData
    ? leveragedTokenData
        .map((lt) => lt.targetAsset)
        .filter((value, index, self) => self.indexOf(value) === index)
    : [];
  const tokenName = getLeveragedTokenSymbol(
    assetId,
    Number(leverage.value),
    isLong
  );
  const mintAmount = getRoundedScaledNumber(collateral, mintTokenBalance);
  const approved =
    approvedAmount !== null &&
    mintTokenBalance !== null &&
    approvedAmount.gte(mintAmount);
  const slippage = getSlippage(Number(leverage.value));
  const expectedOut = useMintAmountOut(
    approvedAmount !== null && mintTokenBalance !== null ? mintAmount : null,
    mintTokenData.address,
    mintWithZap,
    data?.addr || null,
    data?.exchangeRate || null
  );
  const priceImpact =
    mintTokenPrice &&
    data &&
    expectedOut &&
    susdPrice &&
    !mintAmount.isZero() &&
    !expectedOut.isZero()
      ? expectedOut
          .mul(data.exchangeRate.mul(susdPrice))
          .sub(mintAmount.mul(mintTokenPrice))
          .div(mintAmount.mul(mintTokenPrice))
      : null;

  return (
    <StyledMintPage>
      <Seo
        title="Mint Leveraged Tokens"
        description="Mint Leveraged Tokens on Optimism to long or short ETH, BTC, SOL and 50+ more assets"
      />
      <Container>
        <Sections>
          <Section>
            <ChartContainer>
              {chart && <Chart chart={chart} />}
              <LoadingSpinner show={!chart} />
            </ChartContainer>
            <Splitter>
              <MintInfoSection data={data} />
            </Splitter>
          </Section>
          <Section>
            <SubCardFrame>
              <Splitter>
                <InputHeader
                  header="Asset"
                  tooltip="The target asset the leveraged token will track the price of"
                />
                <AssetSelector
                  options={assetOptions.map((asset) => getAssetData(asset))}
                  active={assetId}
                  setActive={setAssetId}
                />
              </Splitter>
              <Splitter>
                <InputHeader
                  header="Direction"
                  tooltip="The direction of the exposure to the target asset"
                />
                <Switch
                  switches={["Long", "Short"]}
                  active={isLong ? "Long" : "Short"}
                  setActive={(active: string) => setIsLong(active === "Long")}
                />
              </Splitter>
            </SubCardFrame>
            <SubCardFrame>
              <Splitter>
                <InputHeader
                  header="Leverage"
                  tooltip="The target leverage of the leveraged token to mint"
                />
                {leverageOptions && (
                  <Radio
                    options={leverageOptions}
                    active={leverage}
                    setActive={(option: RadioOption) => setLeverage(option)}
                  />
                )}
              </Splitter>
              <Splitter>
                <Input
                  number
                  value={collateral}
                  setValue={(value: string) => setCollateral(value)}
                  placeholder={`Enter amount`}
                  max={mintTokenBalance ? mintTokenBalance.toNumber() : 0}
                  tokenOptions={
                    zapAssets
                      ? [
                          ...zapAssets.map((zapAssetAddress: string) => {
                            const id_ = getTokenId(zapAssetAddress);
                            if (!id_) throw new Error("Invalid zap asset");
                            return id_;
                          }),
                          "susd",
                        ]
                      : undefined
                  }
                  token={mintToken}
                  setToken={(mintToken_: string) => {
                    setCollateral("");
                    setMintToken(mintToken_);
                  }}
                  price={mintTokenPrice ? mintTokenPrice.toNumber() : 0}
                />
              </Splitter>
            </SubCardFrame>
            <SubCardFrame>
              {mintWithZap && (
                <InfoLine
                  label="Price Impact"
                  error={
                    !!priceImpact &&
                    priceImpact.toNumber() < -PRICE_IMPACT_WARNING_THRESHOLD
                  }
                  success={
                    !!priceImpact &&
                    priceImpact.toNumber() > PRICE_IMPACT_GAIN_THRESHOLD
                  }
                  tooltip={`When minting, your ${
                    mintTokenData.symbol
                  } is swapped for sUSD to mint ${
                    data?.symbol || "---"
                  }. Swapping large amounts can cause price impact. To minimize price impact, consider swapping your ${
                    mintTokenData.symbol
                  } for sUSD on 1inch or Odos, and then minting with sUSD.`}
                  value={
                    priceImpact
                      ? `${!priceImpact.isNegative() ? "+" : ""} ${round(
                          priceImpact.mul(100).toNumber(),
                          3
                        ).toString()}`
                      : "--"
                  }
                  unit="%"
                />
              )}
              <LeveragedTokenAmount
                assetId={assetId}
                leverage={Number(leverage.value)}
                isLong={isLong}
                exchangeRate={data?.exchangeRate || null}
                expectedOut={expectedOut}
              />
              <Button
                wide
                primary
                web3
                loading={
                  !!data &&
                  !!leveragedToken &&
                  !!mintTokenContract &&
                  !!mintTokenBalance &&
                  !mintAmount.isZero() &&
                  !!approved &&
                  data.hasPendingLeverageUpdate
                }
                disabled={!data || mintAmount.isZero() || !expectedOut}
                action={async () => {
                  if (!leveragedToken) return;
                  if (!data) return;
                  if (!mintTokenContract) return;
                  if (!mintTokenBalance) return;
                  if (!referralContract) return;
                  if (!zap) return;
                  if (!expectedOut) return;

                  if (hasCodeToClaim && !hasClaimedReferralCode) {
                    try {
                      const codeBytes = formatBytes32String(code);
                      const tx = await referralContract.setReferral(codeBytes);
                      await tx.wait();
                    } catch (e: any) {
                      dispatch(
                        setError({
                          message: e.message,
                          source: "Mint/ClaimReferralCode",
                        })
                      );
                    }
                    return;
                  }

                  if (!approved) {
                    try {
                      const tx = await mintTokenContract.approve(
                        mintWithZap ? ZAP_SWAP_ADDRESS : data.addr,
                        mintAmount.value
                      );
                      await tx.wait();
                    } catch (e: any) {
                      dispatch(
                        setError({
                          message: e.message,
                          source: "Mint/Approve",
                        })
                      );
                    }
                    return;
                  }

                  if (mintWithZap) {
                    try {
                      const tx = await zap.mint(
                        mintTokenData.address,
                        data.addr,
                        mintAmount.value,
                        expectedOut.mul((1 - slippage).toString()).value
                      );
                      await tx.wait();
                      setCollateral("");
                      navigate(`/${PORTFOLIO_PATH}`);
                    } catch (e: any) {
                      dispatch(
                        setError({
                          message: e.message,
                          source: "Mint/MintZap",
                        })
                      );
                    }
                  } else {
                    try {
                      const tx = await leveragedToken.mint(
                        mintAmount.value,
                        expectedOut.mul((1 - slippage).toString()).value
                      );
                      await tx.wait();
                      setCollateral("");
                      navigate(`/${PORTFOLIO_PATH}`);
                    } catch (e: any) {
                      dispatch(
                        setError({
                          message: e.message,
                          source: "Mint/Mint",
                        })
                      );
                    }
                  }
                }}
              >
                {mintAmount.isZero()
                  ? "Enter an amount"
                  : !data
                  ? "Select Leveraged Token"
                  : hasCodeToClaim && !hasClaimedReferralCode
                  ? "Claim Referral Code"
                  : !approved
                  ? `Approve ${mintTokenData.symbol}`
                  : `Mint ${tokenName}`}
              </Button>
            </SubCardFrame>
          </Section>
        </Sections>
        <MintingDisabled />
      </Container>
    </StyledMintPage>
  );
};

export default MintPage;

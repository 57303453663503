export const HOME_PATH = "";
export const PRIVACY_POLICY_PATH = "privacy-policy";
export const TOS_PATH = "terms-of-service";
export const STAKE_PATH = "stake";
export const MINT_PATH = "mint";
export const PORTFOLIO_PATH = "portfolio";
export const BOND_PATH = "bond";
export const LOCK_PATH = "lock";
export const REWARDS_PATH = "rewards";
export const VESTING_PATH = "vesting";

export interface AirdropData {
  liquidationPoints: number;
  liquidationPointsShare: number;
  votingShare: number;
  registrationShare: number;
  miladyBlurShare: number;
  miladySnxShare: number;
  totalShare: number;
}

const AIRDROPS: Record<string, AirdropData> = {
  "0xb6bbE5a785F6cfEd3F65F2BA91AD20586B00f7E6": {
    liquidationPoints: 613052,
    liquidationPointsShare: 9278,
    votingShare: 10170,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 22278,
  },
  "0x1140321cCE279B4a2158571eb377669Def562Ac4": {
    liquidationPoints: 350267,
    liquidationPointsShare: 8343,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 21342,
  },
  "0x6c1DdFb81A3666188267296F10253a5A9b5BAe40": {
    liquidationPoints: 308678,
    liquidationPointsShare: 8196,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 21195,
  },
  "0x731826D945a3299A968f0FfbCE47FCA7f86027A8": {
    liquidationPoints: 153180,
    liquidationPointsShare: 7643,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 20642,
  },
  "0x224910152FF0b9Cf2dfcF1931a4866F5d336fc9c": {
    liquidationPoints: 132663,
    liquidationPointsShare: 7570,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 20569,
  },
  "0xB69e74324bc030F1B5409236EFA461496D439116": {
    liquidationPoints: 115757,
    liquidationPointsShare: 7509,
    votingShare: 10170,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 20510,
  },
  "0xD1FB4d140aa11097838C296369c989aa2317BC04": {
    liquidationPoints: 97249,
    liquidationPointsShare: 7444,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 20443,
  },
  "0x50EF8F32B81b5C00555B0af7f7c999155BeE0b26": {
    liquidationPoints: 95562,
    liquidationPointsShare: 7438,
    votingShare: 10170,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 20439,
  },
  "0x6dc88B231Cd04Dd1b1e525161162993F47140006": {
    liquidationPoints: 90370,
    liquidationPointsShare: 7419,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 20418,
  },
  "0xDb9918673D782a8116f583937f2f4A32352F11A2": {
    liquidationPoints: 85258,
    liquidationPointsShare: 7401,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 20400,
  },
  "0xA505c99A1ddB782A6B8e4b97b70F7D402e1E0c17": {
    liquidationPoints: 75738,
    liquidationPointsShare: 7367,
    votingShare: 10170,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 20368,
  },
  "0xAB12253171A0d73df64B115cD43Fe0A32Feb9dAA": {
    liquidationPoints: 68920,
    liquidationPointsShare: 7343,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 20342,
  },
  "0xe96F4E42719542b3f9556EEf6aDA7B819F0AC793": {
    liquidationPoints: 62169,
    liquidationPointsShare: 7319,
    votingShare: 10170,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 20320,
  },
  "0xFee6be6B5cc8Cb4EE8189850A69973E774e7614e": {
    liquidationPoints: 49831,
    liquidationPointsShare: 7275,
    votingShare: 10170,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 20275,
  },
  "0x19319B30be769289e818F8DB238a95FE07a14BC3": {
    liquidationPoints: 27687,
    liquidationPointsShare: 7196,
    votingShare: 10170,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 20196,
  },
  "0x138A87c80d1659AEe5e729221cA4872F51B2937d": {
    liquidationPoints: 27489,
    liquidationPointsShare: 7196,
    votingShare: 10170,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 20196,
  },
  "0xcEfbE9fE07Ca0c111D429839E5B4747A4FdaC5E4": {
    liquidationPoints: 15760,
    liquidationPointsShare: 7154,
    votingShare: 10170,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 20155,
  },
  "0x97579172749e2Bc672e204891a43cBC0F87c8374": {
    liquidationPoints: 15751,
    liquidationPointsShare: 7154,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 20153,
  },
  "0x1a207bEefC754735871CEEb4C506686F044B1c41": {
    liquidationPoints: 12296,
    liquidationPointsShare: 7141,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 20140,
  },
  "0xbF49B454818783D12Bf4f3375ff17C59015e66Cb": {
    liquidationPoints: 507024,
    liquidationPointsShare: 8901,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 19070,
  },
  "0xF68D2BfCecd7895BBa05a7451Dd09A1749026454": {
    liquidationPoints: 377214,
    liquidationPointsShare: 8439,
    votingShare: 10170,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 18609,
  },
  "0x89808C49F858b86E80B892506CF11606Fb25fCDC": {
    liquidationPoints: 340631,
    liquidationPointsShare: 8309,
    votingShare: 10170,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 18479,
  },
  "0xfC9f6BB19C91D6490D8D73F2353d395Fa0892736": {
    liquidationPoints: 331593,
    liquidationPointsShare: 8277,
    votingShare: 10170,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 18447,
  },
  "0xC814d2ef6D893568c74cD969Eb6F72a62fc261f7": {
    liquidationPoints: 316386,
    liquidationPointsShare: 8223,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 18392,
  },
  "0x4Dc7970c0faCD251e4398f706C45f3AF877d029B": {
    liquidationPoints: 210934,
    liquidationPointsShare: 7848,
    votingShare: 10170,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 18018,
  },
  "0xf42a339F93c1fA4c5D9ace33DB308A504E7B0bdE": {
    liquidationPoints: 188766,
    liquidationPointsShare: 7769,
    votingShare: 10170,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 17939,
  },
  "0x29E78e2438E589A0054d45b8a5f6C2eEEE9A68Df": {
    liquidationPoints: 94547,
    liquidationPointsShare: 7434,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 17603,
  },
  "0x86AABcD459587bC1A347aE1E2d15223856354EC9": {
    liquidationPoints: 79969,
    liquidationPointsShare: 7382,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 17551,
  },
  "0xe5BC0Ab963C75148A9F6F14d515a4a547De5d760": {
    liquidationPoints: 79381,
    liquidationPointsShare: 7380,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 17549,
  },
  "0xcF10A8E7c907144cc87721aC1fD7Ac75a8aebeC7": {
    liquidationPoints: 78271,
    liquidationPointsShare: 7376,
    votingShare: 10170,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 17546,
  },
  "0xEceE5497b9dbB82E1804E3224F67D00d8d891c69": {
    liquidationPoints: 77232,
    liquidationPointsShare: 7372,
    votingShare: 10170,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 17542,
  },
  "0x65F570384d6cC157A15eac9Bfd9DA88364D59b7F": {
    liquidationPoints: 74812,
    liquidationPointsShare: 7364,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 17533,
  },
  "0x4b9D8dde4c5cEb5BA567334d9fB86Cfc94282A03": {
    liquidationPoints: 71370,
    liquidationPointsShare: 7352,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 17521,
  },
  "0x1C327d791bc5fe5D444352E5529196d56ec1e952": {
    liquidationPoints: 52681,
    liquidationPointsShare: 7285,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 17454,
  },
  "0x83C6149694Cf9cc16c19a3994660b9121B7036E9": {
    liquidationPoints: 49899,
    liquidationPointsShare: 7275,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 17444,
  },
  "0xAb7Ab9b6495072c1136F96E301bD8f8DE900f119": {
    liquidationPoints: 45372,
    liquidationPointsShare: 7259,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 17428,
  },
  "0x9F07A0233Abc178428Aa9A18Baef3188F093A7d0": {
    liquidationPoints: 45441,
    liquidationPointsShare: 7259,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 17428,
  },
  "0x93DF2Bea2e66d8c4fE547262Bb07975D2774469D": {
    liquidationPoints: 45474,
    liquidationPointsShare: 7259,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 17428,
  },
  "0xA0D86993bf9593A9186c7F022e31bd0d32808BF1": {
    liquidationPoints: 21674,
    liquidationPointsShare: 7175,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 17344,
  },
  "0xCDF156D2AFE53E26866936F289E7d9c8eed4f84d": {
    liquidationPoints: 17685,
    liquidationPointsShare: 7161,
    votingShare: 10170,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 17331,
  },
  "0xC6a8f0f8379f06Ca34F2f9a76864278105A6b2f3": {
    liquidationPoints: 17016,
    liquidationPointsShare: 7158,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 17327,
  },
  "0x26100B99Ed846993733a7d1A335a2F4aF1f1C134": {
    liquidationPoints: 15752,
    liquidationPointsShare: 7154,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 17323,
  },
  "0x2E26fbfd06f0C691Ce8F2e399fC920cD804CdE3d": {
    liquidationPoints: 13306,
    liquidationPointsShare: 7145,
    votingShare: 10170,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 17315,
  },
  "0x6e3AA85dB95BBA36276a37ED93B12B7AB0782aFB": {
    liquidationPoints: 2003499,
    liquidationPointsShare: 14223,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 17053,
  },
  "0x67D21ebD269d61Ef8cD9F0f3698869b3d818a4B5": {
    liquidationPoints: 876884,
    liquidationPointsShare: 10216,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3572,
    totalShare: 13788,
  },
  "0x349A01774f22f51ffD46270b48F1FDC85ac9131f": {
    liquidationPoints: 1857478,
    liquidationPointsShare: 13704,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 13704,
  },
  "0xCde20a235B6Ea05AD0a2F7E3e772c6Cf3a3b2199": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 13001,
  },
  "0xFF51712C8F5b7108D1896B94CE5C183659B82eF7": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 13001,
  },
  "0x01530d9FC9dF9734F2CC111E1ce1E0f5DF4Cc100": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 13001,
  },
  "0x1512BB3b85696Fa4D85Cb4bbf78e9C4FE95DB90F": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 13001,
  },
  "0x2B549BdAF1E053Af0239BCF341Fe37a14bF9208d": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 13001,
  },
  "0x2EBED46bc090CA119aDe44Eb03bcF3A06d765FA3": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 13001,
  },
  "0x2aE8693486cDDC27cFa7665cd5790B8c1B6f9452": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 13001,
  },
  "0x8482a0F31050416AD4344C98bcdb938e9cD3DEa2": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 13001,
  },
  "0x920b4b547ED03A68709b7f28c7D8811df88930d7": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 13001,
  },
  "0xB3a7303de6061B89Ad470569A6d67048F528aA30": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 13001,
  },
  "0xbAA32387bd55553Ec806622d524b12BbB8242a19": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 13000,
  },
  "0x4F813B5cf2750A59A45f3c5e50397D6ac02B64F9": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 13000,
  },
  "0xebF944df22b19f1e68467F32F17A711CEFD6c003": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 13000,
  },
  "0x90e0d37f59B4d3202880d2FB17f3e50b7056f762": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 13000,
  },
  "0x5B5E49565D3804A3A66B9C2497133F8c782B7477": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 13000,
  },
  "0x7B5c3E69349F7C388AAe5CAEe32289d9f1295cBE": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 13000,
  },
  "0x590f7796c7573fAFeD2FCF50f3dbd9Ef79FE51b1": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 13000,
  },
  "0xB71393f5710ABeD5A391A9b48e8Df359A4dd270C": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 13000,
  },
  "0xBD1f7d88C76A86C60d41bDDD4819fAe404e7151E": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 13000,
  },
  "0xf600fd970Bc2054d81AFb1646B50531D7567b22c": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 13000,
  },
  "0x599299DD5ddc7795f9284DCcb2f8c713BC805D8a": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 13000,
  },
  "0xFa5dD9152cF8aF0b1B803230649a604c4D18Fcd8": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 13000,
  },
  "0xEfce38f31Ebeb9637E85D3487595261FDf6ebeEb": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 13000,
  },
  "0x78450a857347a10595aB92214a67d2ac3AeC562F": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 13000,
  },
  "0x697182e21C78b4E321Abd93BfA9587AA38391eF9": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 12999,
  },
  "0x92e547310228815207FF10351e8434759Dc012d4": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 12999,
  },
  "0x3c9E21167338F994A169E94764b7bB3b810C73E0": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 12999,
  },
  "0x58E73d1d39d85B164D1C6CE9A78e570e6302dcd1": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 12999,
  },
  "0x5e0679b7b4C32BFfcde69B5fefEa89E77564FFE1": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 12999,
  },
  "0x634b58342ADc1373cB38C49c2C6701EA9E982563": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 12999,
  },
  "0xB23bF2b33eB8036EE3c507dB5ef2F744b09eA76c": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 12999,
  },
  "0xBcDDD3Ba9C36540125E9662d3FcC2406312B8B05": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 12999,
  },
  "0x0bd7CbfEa2E0E5baCf8e91f20213367E69b6ddd8": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 12999,
  },
  "0x7BDaA0E15Fdb8f5592bcF7e584cdC300d7F65cE3": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 12999,
  },
  "0xb8aE99AD5f58C87030fc03627377B4b7433031ca": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 12999,
  },
  "0x9c9FFD35434F48D912aD3a116cE58a34a39e3B44": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 12999,
  },
  "0x5EbfF65F3bd158968d750662cc80917cd6577d6a": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 12999,
  },
  "0x07d16dff4F7D1Ff465eC6479c1A2c5487d9AeBb8": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 12999,
  },
  "0x799D99103868Cda7422995AcCEB36Df2CA646a38": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 12999,
  },
  "0x839d36595175E1F678110d52D8265a2FEa9607bA": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 12999,
  },
  "0x5605acf8398d1dAdcda8beC8aECaBD08729bCd95": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 12999,
  },
  "0x94cfFb35a4187d93840e13d735ca5784Ba074BCF": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 12999,
  },
  "0xE1f02F7E90ea5F21D0AC6F12c659C3484c143B03": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 12999,
  },
  "0x15DbaDA0b641e0036852cDaA944df2Cf003782ad": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 12999,
  },
  "0x07C572233D04De64724D6229aa60C6E60C2e88FB": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 12999,
  },
  "0x42f9134E9d3Bf7eEE1f8A5Ac2a4328B059E7468c": {
    liquidationPoints: 615821,
    liquidationPointsShare: 9288,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3571,
    totalShare: 12859,
  },
  "0x9CBF099ff424979439dFBa03F00B5961784c06ce": {
    liquidationPoints: 246720,
    liquidationPointsShare: 7975,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3572,
    totalShare: 11547,
  },
  "0x2BeA8d5556496b882D374b1d353D7c8b02993cD7": {
    liquidationPoints: 3362,
    liquidationPointsShare: 7110,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3572,
    totalShare: 10682,
  },
  "0x547056FCe2E08ed24fEAc022FBdCB23A8942bC81": {
    liquidationPoints: 173782,
    liquidationPointsShare: 7716,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10546,
  },
  "0x018439894b681cC2aAdDB613A425a1cfA6e59c29": {
    liquidationPoints: 95214,
    liquidationPointsShare: 7436,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10266,
  },
  "0xfba381fB57D1f1efDc12661e5bE59425E545e649": {
    liquidationPoints: 889284,
    liquidationPointsShare: 10260,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10260,
  },
  "0x0A7562526191F8c9b49291768b847501caf3D11d": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10170,
  },
  "0x7692ef3dCc4D2eCd497C1a262c50Ed22B925A513": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10170,
  },
  "0xA9903BDA477b9A57BD795AdFf9922cB98DB65F04": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10170,
  },
  "0x4cC526CD48cC4b571bc92F3eCE2433F149f87536": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10170,
  },
  "0xF373b1109C1570135AD9F17519416d1D334360c6": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10170,
  },
  "0x21A8567046fF3C287CAeAEF977133FC051b588bC": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10170,
  },
  "0xfB21f8a7A67C1846B1034d13aE118855AD1b8da0": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10170,
  },
  "0x09Fc1031D03A78eD4f60463FA9749870d33439BC": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10170,
  },
  "0x51cD838F1B536f3116546e1E97a9EBF188acB7d9": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10170,
  },
  "0xB631651cB570bA0C0D696bD5E4860cbD214f20a0": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10170,
  },
  "0x67c188Ef26fac4A50Cf64C2B0dFfe6f332704568": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10170,
  },
  "0x76a1272a128b76FA3BEe660E3860e560e296D665": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10170,
  },
  "0x00b3eCC795d7f3C3a0416be0784c43B652D5f4Ca": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10170,
  },
  "0xd46c81245A0582891c2249daf2d68925977cF2a7": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10170,
  },
  "0x52dB554E067C721c26CC9D8edF61E66AF7346a93": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10170,
  },
  "0x8252C3Ad7008464A618B6b28690DFB30D17A4910": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10170,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10170,
  },
  "0xBbb33d2e7bD7ddC722E53Da9cA8ee97Df41CfaBF": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10169,
  },
  "0x2BC4cF75f44f8F81eEe9FF788fCC8eABF277e539": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10169,
  },
  "0x2421134C8E8278ad199f6886ae70C5C373dA3b48": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10169,
  },
  "0x0a653bd08E2B0A80d1D212d33032953Db8298efd": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10169,
  },
  "0x6a85C3BfC93D59B9746152A35832FD3255f7F930": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10169,
  },
  "0x29008af9714958f643dED5f145B16266247E8b4b": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10169,
  },
  "0xa24727B0B849732db0258B6fe1b7f3E325d40F76": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10169,
  },
  "0x1Dacc1Aa3c731FDD83a74f6a9C534C265B5eD425": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10169,
  },
  "0x3038428B55E18346b43c36ACc98eE6bA61B4a6c8": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10169,
  },
  "0x7B0507E0b2Feb81B3fd618Ac13e85C3f1980738A": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10169,
  },
  "0x34C9d6229DE541E2Fa77E60835f5106D5842BBb3": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10169,
  },
  "0x0045fa93DEDB0af02956f7D7C306b34263688872": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10169,
  },
  "0x57C4D1B3e2bD6c456a0d272Cc76320F604E2C8d2": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10169,
  },
  "0x0A04362FbdC46b8af04e26246fa07CC636A7790b": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 10169,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10169,
  },
  "0xBCbeAc56eeF250E8a4859be46c6cBFd93aAe5d2F": {
    liquidationPoints: 844100,
    liquidationPointsShare: 10100,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10100,
  },
  "0x3e3672B23eB22946A31263d2D178bF0fB1f4BBFD": {
    liquidationPoints: 28831,
    liquidationPointsShare: 7200,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 10030,
  },
  "0x95Fb91A445e603DdA39E367226Fd3eEb9CAe97db": {
    liquidationPoints: 742857,
    liquidationPointsShare: 9740,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 9740,
  },
  "0x7bdc57A20bEa89f99ca021F1cfC8D9eD772D5415": {
    liquidationPoints: 742649,
    liquidationPointsShare: 9739,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 9739,
  },
  "0x3dbD11cE12819B78622faE55C76f082ddb663730": {
    liquidationPoints: 701436,
    liquidationPointsShare: 9592,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 9592,
  },
  "0x464FbeB7D6a5d459F58251ed135D00A824e5b4F8": {
    liquidationPoints: 700100,
    liquidationPointsShare: 9588,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 9588,
  },
  "0xbd3a148D241CFEaA37BBB32369c2BA71C7D44EBB": {
    liquidationPoints: 672843,
    liquidationPointsShare: 9491,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 9491,
  },
  "0xe6945Ed413f233e248BCdF2A5AF249B6af7cd58c": {
    liquidationPoints: 664380,
    liquidationPointsShare: 9461,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 9461,
  },
  "0x1C485A4AA0A646C9aF19E8500902054146ad2FE2": {
    liquidationPoints: 632809,
    liquidationPointsShare: 9348,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 9348,
  },
  "0xf1c964Ff98F36a60ba7C894349AB1aA21BBae5AB": {
    liquidationPoints: 631309,
    liquidationPointsShare: 9343,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 9343,
  },
  "0xdad18e6Af9886aD4A9b9ed3Ab9A50D33B3F58E7D": {
    liquidationPoints: 610064,
    liquidationPointsShare: 9267,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 9267,
  },
  "0x7a7C5a94495C9c9c60D88a85f7e43b657C3B6B5C": {
    liquidationPoints: 608324,
    liquidationPointsShare: 9261,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 9261,
  },
  "0x370a5275F69CB3C2E76CFe575cF7f846f906b34D": {
    liquidationPoints: 574497,
    liquidationPointsShare: 9141,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 9141,
  },
  "0x57478b221022952C825668f105D2EE29c8B87ba4": {
    liquidationPoints: 563010,
    liquidationPointsShare: 9100,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 9100,
  },
  "0x590ce964C5Bc43b8903B013631c64f7bCf950B99": {
    liquidationPoints: 540825,
    liquidationPointsShare: 9021,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 9021,
  },
  "0x0bc3668d2AaFa53eD5E5134bA13ec74ea195D000": {
    liquidationPoints: 505551,
    liquidationPointsShare: 8896,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8896,
  },
  "0x9194eFdF03174a804f3552F4F7B7A4bB74BaDb7F": {
    liquidationPoints: 494230,
    liquidationPointsShare: 8855,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8855,
  },
  "0x5Cec084Ab54CFC07785c766a74aB8e921BC6eAEe": {
    liquidationPoints: 482537,
    liquidationPointsShare: 8814,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8814,
  },
  "0xf658269Ca1B33E2557F0E0B5088748cbE71B627b": {
    liquidationPoints: 477474,
    liquidationPointsShare: 8796,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8796,
  },
  "0xfC8333829a69d922B9d691F4D76185BDf4F47642": {
    liquidationPoints: 471385,
    liquidationPointsShare: 8774,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8774,
  },
  "0xb57bC9b9a0b021103052cCd8c7ca30acc4F41612": {
    liquidationPoints: 445755,
    liquidationPointsShare: 8683,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8683,
  },
  "0xE996148026c14ec87741D7d34592035Fe61967F7": {
    liquidationPoints: 429445,
    liquidationPointsShare: 8625,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8625,
  },
  "0xd8e80c06f9F3A41c85cAd28117e01ebD50fd535e": {
    liquidationPoints: 418117,
    liquidationPointsShare: 8585,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8585,
  },
  "0x2EA1fd954B5d21FF704e37DfE50f796Eec4620cD": {
    liquidationPoints: 416431,
    liquidationPointsShare: 8579,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8579,
  },
  "0xdCB7e8f1B586c94dDE9b430557E4Df6727779ACA": {
    liquidationPoints: 402912,
    liquidationPointsShare: 8531,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8531,
  },
  "0xbe3f79aE0ab3294AAa3230c1155E912c05B6a55B": {
    liquidationPoints: 394667,
    liquidationPointsShare: 8501,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8501,
  },
  "0x9e5A57470A2a136a3d4De39e1996E05944C4b1fb": {
    liquidationPoints: 373260,
    liquidationPointsShare: 8425,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8425,
  },
  "0x2C678004AF4c1e217d9ED8Baabd4454406CeB63D": {
    liquidationPoints: 372228,
    liquidationPointsShare: 8422,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8422,
  },
  "0x3dF39EB04417cf15c030759066a2a98209C5D53C": {
    liquidationPoints: 369503,
    liquidationPointsShare: 8412,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8412,
  },
  "0x186E20ae3530520C9F3E6C46F2f5d1062b784761": {
    liquidationPoints: 368832,
    liquidationPointsShare: 8410,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8410,
  },
  "0x1Bd229480417DE6765ED7b3cDB04F4e3FfA26438": {
    liquidationPoints: 363631,
    liquidationPointsShare: 8391,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8391,
  },
  "0xCB0E63608D3C1E9e3096bD7Ed1442C4f42c04FA7": {
    liquidationPoints: 348749,
    liquidationPointsShare: 8338,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8338,
  },
  "0xDDf8dA887a1D5C07d05A948856b79DaB091efc78": {
    liquidationPoints: 347036,
    liquidationPointsShare: 8332,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8332,
  },
  "0x9fa23D27bC93533cd29E6038275611C829813147": {
    liquidationPoints: 323758,
    liquidationPointsShare: 8249,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8249,
  },
  "0x57F39a945D28593B7fd9c0e54C66364D795EF6ba": {
    liquidationPoints: 318979,
    liquidationPointsShare: 8232,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8232,
  },
  "0xd23199F1222C418ffC74c385171330B21B16e452": {
    liquidationPoints: 312236,
    liquidationPointsShare: 8208,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8208,
  },
  "0xcFBa6D63EC822425775d3C509E60ff689C1Ae1e7": {
    liquidationPoints: 310095,
    liquidationPointsShare: 8201,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8201,
  },
  "0x0dEf0BaCf09a27E0836EF3096F9c3AE859aC13eA": {
    liquidationPoints: 302570,
    liquidationPointsShare: 8174,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8174,
  },
  "0x60fe5Cbd886A778f584FFCC63833B068104D1f77": {
    liquidationPoints: 297037,
    liquidationPointsShare: 8154,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8154,
  },
  "0x595799E89DCFB6b7DF7a64A1b4fCc8f5bA8729b9": {
    liquidationPoints: 285905,
    liquidationPointsShare: 8115,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8115,
  },
  "0x7edBA0F13e5aa11bD5C1FB96AB25d26f1907fd1a": {
    liquidationPoints: 278600,
    liquidationPointsShare: 8089,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8089,
  },
  "0x55179ffEFc2d49daB14BA15D25fb023408450409": {
    liquidationPoints: 272796,
    liquidationPointsShare: 8068,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8068,
  },
  "0x138Dd537D56F2F2761a6fC0A2A0AcE67D55480FE": {
    liquidationPoints: 266063,
    liquidationPointsShare: 8044,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8044,
  },
  "0x88277E19Cf63f3b71c84dB256f1a27DCD03C9c21": {
    liquidationPoints: 261272,
    liquidationPointsShare: 8027,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8027,
  },
  "0x6Ff60E1CF749aE639fBE124dDbC2178c4C988203": {
    liquidationPoints: 261095,
    liquidationPointsShare: 8026,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8026,
  },
  "0xA0c9aB7cb590C01D9F36D0F1eb960A544c31467e": {
    liquidationPoints: 258261,
    liquidationPointsShare: 8016,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8016,
  },
  "0x99f00544780Ae555D8BFEA42ED0B2a657Ee307c2": {
    liquidationPoints: 257966,
    liquidationPointsShare: 8015,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8015,
  },
  "0x78f214cf1287b5c97df55a285E885b09C0d43e3D": {
    liquidationPoints: 253903,
    liquidationPointsShare: 8001,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8001,
  },
  "0xe29B7fB40150ea4B7cbC2f22e3ACFA67A8da021a": {
    liquidationPoints: 253884,
    liquidationPointsShare: 8001,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 8001,
  },
  "0xD759D2Dc1Ffdf1cEAcD1c05deD4748D1793dD7f4": {
    liquidationPoints: 239986,
    liquidationPointsShare: 7951,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7951,
  },
  "0xa982BbDaf783Eb7C3914D988b7e2a0bE865ccB53": {
    liquidationPoints: 239236,
    liquidationPointsShare: 7949,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7949,
  },
  "0xa5B643C7128d53204A838c852a012691C8a39f93": {
    liquidationPoints: 238280,
    liquidationPointsShare: 7945,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7945,
  },
  "0xcffd91182d94fB37a49fbD626400468197291cCd": {
    liquidationPoints: 235326,
    liquidationPointsShare: 7935,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7935,
  },
  "0x1Bd477FC34B96aB561156F2438B8F9a18FCB5673": {
    liquidationPoints: 230087,
    liquidationPointsShare: 7916,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7916,
  },
  "0x21d5E578Dc101a132b1457f8a696E8EEFF97A4B1": {
    liquidationPoints: 228110,
    liquidationPointsShare: 7909,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7909,
  },
  "0x7fd8A3e656C90A0e3cd703e4bA46aa4C9d9e84fB": {
    liquidationPoints: 226987,
    liquidationPointsShare: 7905,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7905,
  },
  "0x22E9aCc5e0410aE77ca95F904d811713f56BF721": {
    liquidationPoints: 225091,
    liquidationPointsShare: 7898,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7898,
  },
  "0x4CAe5BED586f6E73Ae54EbD40A4AC4eD2c477C34": {
    liquidationPoints: 220865,
    liquidationPointsShare: 7883,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7883,
  },
  "0xcdA16A18d3F942571E7ba81B7D2219A6802C45A1": {
    liquidationPoints: 220149,
    liquidationPointsShare: 7881,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7881,
  },
  "0xaadb7CC8587cA5aE3e5c98B9e78F6024953C0Bd0": {
    liquidationPoints: 219339,
    liquidationPointsShare: 7878,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7878,
  },
  "0x704a3117090256be6A6DB52D7d5af823ad569C7A": {
    liquidationPoints: 217550,
    liquidationPointsShare: 7871,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7871,
  },
  "0x6Ad9a8b10F8e8E3883aCAbbBF3d808B156E33Ec7": {
    liquidationPoints: 216198,
    liquidationPointsShare: 7867,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7867,
  },
  "0xfd90ad81Ad93D9af1008A513d964f6fBc589034D": {
    liquidationPoints: 212784,
    liquidationPointsShare: 7855,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7855,
  },
  "0xB12c84cD6EE14f819261Fe34FcA5aA86269a735C": {
    liquidationPoints: 208824,
    liquidationPointsShare: 7840,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7840,
  },
  "0x9AD4c54F19dEf69b456C675B465E30EC470c0AF0": {
    liquidationPoints: 208052,
    liquidationPointsShare: 7838,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7838,
  },
  "0xAE514EA64196f5517b16a131efA63B6C92f4Cafa": {
    liquidationPoints: 204891,
    liquidationPointsShare: 7826,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7826,
  },
  "0x6C650D33929D527DA8ee341F8Fb472E8c0CE5f73": {
    liquidationPoints: 203358,
    liquidationPointsShare: 7821,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7821,
  },
  "0xF7401728edd23290d8099eD73f155101dCf8CC0C": {
    liquidationPoints: 202877,
    liquidationPointsShare: 7819,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7819,
  },
  "0x069Fe5844CdA87185c9eaD8CB64A774655D65E74": {
    liquidationPoints: 202498,
    liquidationPointsShare: 7818,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7818,
  },
  "0x806bB3fc1551dBC9121b6D676bF461b8f7341820": {
    liquidationPoints: 201024,
    liquidationPointsShare: 7813,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7813,
  },
  "0xdE8F8849579F06a30C0dd3ae3d5408EbDaAaBCF7": {
    liquidationPoints: 200982,
    liquidationPointsShare: 7813,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7813,
  },
  "0xF1010eE787Ee588766b441d7cC397b40DdFB17a3": {
    liquidationPoints: 198951,
    liquidationPointsShare: 7805,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7805,
  },
  "0xbA743394ca6c38886c066E45bb9980A09AeC7A65": {
    liquidationPoints: 198140,
    liquidationPointsShare: 7802,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7802,
  },
  "0x841382062d00f024A7a4Fee09e42e08DF41dD714": {
    liquidationPoints: 195248,
    liquidationPointsShare: 7792,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7792,
  },
  "0x2238C6F75DefFAd03f61537Ce40c434a7c23a7a0": {
    liquidationPoints: 194529,
    liquidationPointsShare: 7790,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7790,
  },
  "0xf9a5B3CE7A20B319DCe61E8D8b187877c29A1426": {
    liquidationPoints: 192322,
    liquidationPointsShare: 7782,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7782,
  },
  "0x498F119215f978B66886A4f905658E515F3724d5": {
    liquidationPoints: 191469,
    liquidationPointsShare: 7779,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7779,
  },
  "0xd6255aE13aC335b347aa846802aD6aC39dd2543a": {
    liquidationPoints: 190493,
    liquidationPointsShare: 7775,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7775,
  },
  "0xC935B59c7f810cEC91e46B45F03A4ac4Cc0e915e": {
    liquidationPoints: 185839,
    liquidationPointsShare: 7759,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7759,
  },
  "0x8f009A96F45514C31EE806fBA7C4BfB842ff497E": {
    liquidationPoints: 185371,
    liquidationPointsShare: 7757,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7757,
  },
  "0xa31066B50399387BA8c23704fcCf0992eaF7254d": {
    liquidationPoints: 183352,
    liquidationPointsShare: 7750,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7750,
  },
  "0x752a556Cc099ca902B825EC59A351eC9673EDf25": {
    liquidationPoints: 181113,
    liquidationPointsShare: 7742,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7742,
  },
  "0xE6426915f7BFa6DD7EA215715e643b4C1aDcfBD5": {
    liquidationPoints: 179192,
    liquidationPointsShare: 7735,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7735,
  },
  "0x38032F326436FdB9C7a9B359E90010F86b8AB482": {
    liquidationPoints: 178538,
    liquidationPointsShare: 7733,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7733,
  },
  "0xcC15F837dBf16d9d7f311a7Ce19f495893497dd1": {
    liquidationPoints: 173316,
    liquidationPointsShare: 7714,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7714,
  },
  "0xF3f925DaE7CBf25621326B7782589aD5Ae82062D": {
    liquidationPoints: 171681,
    liquidationPointsShare: 7708,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7708,
  },
  "0x6fb737D1Ebb73cda6CFA36Fd16D9273065D1b084": {
    liquidationPoints: 169806,
    liquidationPointsShare: 7702,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7702,
  },
  "0x9F0A988EDC96DaC834c158ebfE667dA932bD87C6": {
    liquidationPoints: 169158,
    liquidationPointsShare: 7699,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7699,
  },
  "0xAe69719767E23D7e7E32912EB720eaB9D52AAb6D": {
    liquidationPoints: 168693,
    liquidationPointsShare: 7698,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7698,
  },
  "0xC162977c10827BA32c818682188725043F9D4Af7": {
    liquidationPoints: 166554,
    liquidationPointsShare: 7690,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7690,
  },
  "0x62365E0af7b6A188D063825AFa3399Ba0b5AA7Cd": {
    liquidationPoints: 164685,
    liquidationPointsShare: 7683,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7683,
  },
  "0xb414191Ea9c943CAA4431c48a7e7D23d592f3B29": {
    liquidationPoints: 163915,
    liquidationPointsShare: 7681,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7681,
  },
  "0x0339339653c106786180838dC0C0AB6fFC0Cc465": {
    liquidationPoints: 161938,
    liquidationPointsShare: 7674,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7674,
  },
  "0x65A8Bf27a5A00fF43721c535E1C0db23547bE14c": {
    liquidationPoints: 157850,
    liquidationPointsShare: 7659,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7659,
  },
  "0x1b90B46F9Bd6BDcF0adDc4D0601dFAD832FDB1bD": {
    liquidationPoints: 157301,
    liquidationPointsShare: 7657,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7657,
  },
  "0xBE783B019bAe0740797D9A8215A7ae44F6951E41": {
    liquidationPoints: 155989,
    liquidationPointsShare: 7653,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7653,
  },
  "0xb7E309b2fbA55a46D4FCB18E000Ef27e6BA04814": {
    liquidationPoints: 155871,
    liquidationPointsShare: 7652,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7652,
  },
  "0xf9303877F107F5cd2cB0654b6d7A6D749FA03856": {
    liquidationPoints: 155456,
    liquidationPointsShare: 7651,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7651,
  },
  "0xabD02eDfBFF25a307A5C7d827Eb58C2037da9453": {
    liquidationPoints: 155316,
    liquidationPointsShare: 7650,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7650,
  },
  "0x92aE9Aca769778610d1485130e382Ca5A3e4f469": {
    liquidationPoints: 154606,
    liquidationPointsShare: 7648,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7648,
  },
  "0x807d00EBcA7F43a889cB0C1918a7e6d6Bc64460c": {
    liquidationPoints: 153437,
    liquidationPointsShare: 7643,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7643,
  },
  "0x82984A263c8598738e7f64F2d94399F2C7e2f14C": {
    liquidationPoints: 152899,
    liquidationPointsShare: 7642,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7642,
  },
  "0x420e4aE5ea2138a7215dfC6c4cec11F726067659": {
    liquidationPoints: 152417,
    liquidationPointsShare: 7640,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7640,
  },
  "0x24c35D308f4771930f24ca2Ed9FC911E2785572C": {
    liquidationPoints: 151653,
    liquidationPointsShare: 7637,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7637,
  },
  "0x5B17Df2e7E1fA1e1A5F3b10f6b5077F9C2B1b3ad": {
    liquidationPoints: 150318,
    liquidationPointsShare: 7632,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7632,
  },
  "0xB7BaBe35CE543e2Cf2F615CB1c792a2025feb572": {
    liquidationPoints: 150265,
    liquidationPointsShare: 7632,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7632,
  },
  "0xfb32044feC34994BBfB2108fA24F4cEbaCeFE3B2": {
    liquidationPoints: 149864,
    liquidationPointsShare: 7631,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7631,
  },
  "0x65D674d359F3e79F16c1603C649855fe0564594B": {
    liquidationPoints: 149810,
    liquidationPointsShare: 7631,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7631,
  },
  "0x128fAE9d18a2f70d98A03C26bf17B67d97cE1F3C": {
    liquidationPoints: 144502,
    liquidationPointsShare: 7612,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7612,
  },
  "0x50eb42Fe63fad9cB6c1ED585aE8951b3a6b0FC24": {
    liquidationPoints: 141416,
    liquidationPointsShare: 7601,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7601,
  },
  "0x42D29E6fFfEf7D2df66bA652242Eeff2B01822B1": {
    liquidationPoints: 141061,
    liquidationPointsShare: 7599,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7599,
  },
  "0xaC9c065B1A84A486628fd20efc9426E435bA7Cc2": {
    liquidationPoints: 139221,
    liquidationPointsShare: 7593,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7593,
  },
  "0x547B36b9710B5fDbcd89aBf983c2B944bfc6C073": {
    liquidationPoints: 137660,
    liquidationPointsShare: 7587,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7587,
  },
  "0xb8b0CC3793BBbfdb997FeC45828F172e5423D3E2": {
    liquidationPoints: 135000,
    liquidationPointsShare: 7578,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7578,
  },
  "0xF60595B5Bb6fa8135C0Feef89543521FF38Ba83E": {
    liquidationPoints: 134941,
    liquidationPointsShare: 7578,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7578,
  },
  "0x37FfBBeE75B446b2854EfFF46736642bE7a70A95": {
    liquidationPoints: 130672,
    liquidationPointsShare: 7562,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7562,
  },
  "0xF2829fC923b6fD040Ead55d3F973F2953e86D13e": {
    liquidationPoints: 129392,
    liquidationPointsShare: 7558,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7558,
  },
  "0x52F8B09FAc8E1B7D7dC45643CA27d0f573814AB8": {
    liquidationPoints: 129069,
    liquidationPointsShare: 7557,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7557,
  },
  "0xE216696cA4E47c487B2CDD4f6698f0357D8c0459": {
    liquidationPoints: 128064,
    liquidationPointsShare: 7553,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7553,
  },
  "0x4f35758BaFA8bCc451BAbcDE1058174ddE7712EF": {
    liquidationPoints: 128038,
    liquidationPointsShare: 7553,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7553,
  },
  "0xEae03EB54eB26B38057544895E834aF42fc46A69": {
    liquidationPoints: 127616,
    liquidationPointsShare: 7552,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7552,
  },
  "0xbBEeb938553d62D4220296DEd8A3d5bB990e2038": {
    liquidationPoints: 126403,
    liquidationPointsShare: 7547,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7547,
  },
  "0xb3522064694Ac9870Dbf00eEBC2712762193Bb64": {
    liquidationPoints: 125536,
    liquidationPointsShare: 7544,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7544,
  },
  "0xD1A0B66835D830e9ada42eEf436f3AA8005b20B5": {
    liquidationPoints: 124304,
    liquidationPointsShare: 7540,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7540,
  },
  "0x1fbCD07F33349bfB0b63707CF29B3AeCf3d49209": {
    liquidationPoints: 124395,
    liquidationPointsShare: 7540,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7540,
  },
  "0xf96A10dcB261Aa932a589146E87D80E4Cc8446F2": {
    liquidationPoints: 123298,
    liquidationPointsShare: 7536,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7536,
  },
  "0x937542251D5e93EdA4D12A63c5773391aed14433": {
    liquidationPoints: 122772,
    liquidationPointsShare: 7534,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7534,
  },
  "0xCeeC48581B3145a575508719f45da07dc57fA7ce": {
    liquidationPoints: 120969,
    liquidationPointsShare: 7528,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7528,
  },
  "0x14B9298506f86C12485dc1dA2E2C0734A6edC222": {
    liquidationPoints: 120125,
    liquidationPointsShare: 7525,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7525,
  },
  "0x0ac0240f4656dc80684d0df1208Bb91c0220725D": {
    liquidationPoints: 116989,
    liquidationPointsShare: 7514,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7514,
  },
  "0xC05320F54075452fF1a5ce420D64c9D025A452BB": {
    liquidationPoints: 115529,
    liquidationPointsShare: 7509,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7509,
  },
  "0x2aa9b58b966D97E07d40D9D1B6f25e4CFf7d449c": {
    liquidationPoints: 114409,
    liquidationPointsShare: 7505,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7505,
  },
  "0x12b2946224Aa50Cc88Cd026B9bDC19631a96564e": {
    liquidationPoints: 112219,
    liquidationPointsShare: 7497,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7497,
  },
  "0x777777125C91be606997038d50aD039dD7caa1a3": {
    liquidationPoints: 111849,
    liquidationPointsShare: 7496,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7496,
  },
  "0xa58Cc23a546b6cE08EE258cfb54D92d4cC151Ba4": {
    liquidationPoints: 111704,
    liquidationPointsShare: 7495,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7495,
  },
  "0x3cc1f8a4D303D65e5a70eEC9ab747000e57fF741": {
    liquidationPoints: 111794,
    liquidationPointsShare: 7495,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7495,
  },
  "0xEE57D8989170370a6f88B2f99395b81204117bf2": {
    liquidationPoints: 110232,
    liquidationPointsShare: 7490,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7490,
  },
  "0x8be60fe9F7C8d940D8DA9d5dDD0D8E0c15A4288B": {
    liquidationPoints: 108928,
    liquidationPointsShare: 7485,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7485,
  },
  "0x0b9B90BCF3fA83A933668e072611AF7805fe84Ad": {
    liquidationPoints: 108649,
    liquidationPointsShare: 7484,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7484,
  },
  "0x41c66454192d0d6A923847987f8F80AcB7c6C466": {
    liquidationPoints: 105493,
    liquidationPointsShare: 7473,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7473,
  },
  "0x55bcC5C24Ef24FE3483D1df138db0eB648342aB8": {
    liquidationPoints: 104568,
    liquidationPointsShare: 7470,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7470,
  },
  "0x2f42D303b34c29E1F3FEB9CE5d5355f1e101f99D": {
    liquidationPoints: 104184,
    liquidationPointsShare: 7468,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7468,
  },
  "0xbFd80971023eC42C236979015657bbBE3F05a0a1": {
    liquidationPoints: 102897,
    liquidationPointsShare: 7464,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7464,
  },
  "0x223C381a3aaE44F7E073e66a8295DCe2955E0098": {
    liquidationPoints: 101430,
    liquidationPointsShare: 7458,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7458,
  },
  "0xc9e7D61bbDEF3F821DA08Bfdb19a2bf7e0eC60B7": {
    liquidationPoints: 98634,
    liquidationPointsShare: 7449,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7449,
  },
  "0xf0A9E090416FCBc8C9403CE6BC674239Df0a535e": {
    liquidationPoints: 96915,
    liquidationPointsShare: 7442,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7442,
  },
  "0x640619Af8b2820E0b1F7eac15D0b769A78225715": {
    liquidationPoints: 96863,
    liquidationPointsShare: 7442,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7442,
  },
  "0x526B7978c6e747Dd7C44C7aE33DE57bddae19342": {
    liquidationPoints: 95170,
    liquidationPointsShare: 7436,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7436,
  },
  "0x5A0f2C1d8E563db79Ee40F979F6bcD0d27f86f80": {
    liquidationPoints: 94834,
    liquidationPointsShare: 7435,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7435,
  },
  "0x41574C34BfEBD8E87DaEF8BFB825966Ef75132B6": {
    liquidationPoints: 92301,
    liquidationPointsShare: 7426,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7426,
  },
  "0x9C529Af39499Eec506aC31fDfA13bb299C2C2A55": {
    liquidationPoints: 90974,
    liquidationPointsShare: 7421,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7421,
  },
  "0xd114B8E35b36E09F5d5cdbDDF0d42f8d150B420E": {
    liquidationPoints: 90958,
    liquidationPointsShare: 7421,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7421,
  },
  "0x322dF02e188966F210fc6537Fc7859C637E29cA3": {
    liquidationPoints: 90435,
    liquidationPointsShare: 7419,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7419,
  },
  "0xC789026A0f0b15C532c77405491331997F2b2bBC": {
    liquidationPoints: 88868,
    liquidationPointsShare: 7414,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7414,
  },
  "0xf7650312287b41Db391B5803c2a725846A43bb2a": {
    liquidationPoints: 88438,
    liquidationPointsShare: 7412,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7412,
  },
  "0x8eFD9ADDD8de6A4E64664D1893dEc51F8C3339E9": {
    liquidationPoints: 88012,
    liquidationPointsShare: 7411,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7411,
  },
  "0x28a48d16D5E8D79F6de73f2c1b3B9e423f95B9F5": {
    liquidationPoints: 87181,
    liquidationPointsShare: 7408,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7408,
  },
  "0x3f491c542a449243C7a33457847245445C747F48": {
    liquidationPoints: 86744,
    liquidationPointsShare: 7406,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7406,
  },
  "0x156E6C5a2Fac34bB2Fcf2Ac1bbAA0E75BDE3aC4F": {
    liquidationPoints: 84530,
    liquidationPointsShare: 7398,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7398,
  },
  "0xCF5Cdc314Aab1078FFABa02c64F8757818f2b8C1": {
    liquidationPoints: 84185,
    liquidationPointsShare: 7397,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7397,
  },
  "0x9E2bA399bEBD28ebF48Bae66739fA87DacBB1114": {
    liquidationPoints: 84222,
    liquidationPointsShare: 7397,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7397,
  },
  "0x528836082e417D7b839E17a17964694266AA29a5": {
    liquidationPoints: 83265,
    liquidationPointsShare: 7394,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7394,
  },
  "0x9AA00D3eADDA7017f30BEd9d483b873776Bd251c": {
    liquidationPoints: 83247,
    liquidationPointsShare: 7394,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7394,
  },
  "0x6CD1B82490Eb7aA18beC4305C6c2E691194C6898": {
    liquidationPoints: 82923,
    liquidationPointsShare: 7393,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7393,
  },
  "0xFD9ce79FD7F62ca88acE958cD2716f4CCe25e2DF": {
    liquidationPoints: 82463,
    liquidationPointsShare: 7391,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7391,
  },
  "0x0dC89D5CFb8b4dfE0021C5F2F457689587ddCAEC": {
    liquidationPoints: 78906,
    liquidationPointsShare: 7378,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7378,
  },
  "0x0EAfd23a586B190de1E344d4A4aAAB3f88631686": {
    liquidationPoints: 78868,
    liquidationPointsShare: 7378,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7378,
  },
  "0xf7174d52e402D88696d3703a11eE5D0594a2Dd84": {
    liquidationPoints: 78333,
    liquidationPointsShare: 7376,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7376,
  },
  "0x0EEaCD4c475040463389d15EAd034d1291b008b1": {
    liquidationPoints: 78096,
    liquidationPointsShare: 7376,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7376,
  },
  "0x488787C86e4F21011e9F8b635E2aa2F960f07fC8": {
    liquidationPoints: 76898,
    liquidationPointsShare: 7371,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7371,
  },
  "0x78ff21cAe3254fbDCB799B0818597E5eFCC0CBc5": {
    liquidationPoints: 75983,
    liquidationPointsShare: 7368,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7368,
  },
  "0x7DD90dE9572e4A605747634A15CB90c73DC51359": {
    liquidationPoints: 74769,
    liquidationPointsShare: 7364,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7364,
  },
  "0x2AB7249404146652fbd5BaeC337B66BcF8070cE3": {
    liquidationPoints: 74286,
    liquidationPointsShare: 7362,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7362,
  },
  "0x6A0B55b607D3B2104E4a1ac1dc53bE77d2A9E145": {
    liquidationPoints: 74017,
    liquidationPointsShare: 7361,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7361,
  },
  "0xB8e1f0f1E0AaB629eC07aE9e4FA680B58732c643": {
    liquidationPoints: 73511,
    liquidationPointsShare: 7359,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7359,
  },
  "0x7314F48B229909b088f6C73353A5aB1dbb00385F": {
    liquidationPoints: 73108,
    liquidationPointsShare: 7358,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7358,
  },
  "0xb70AE0bbf799c0638a6f59cEC37af20A2503aD91": {
    liquidationPoints: 72251,
    liquidationPointsShare: 7355,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7355,
  },
  "0x4864E9BA3Ab4270E338D1d52a49c383294C34134": {
    liquidationPoints: 72200,
    liquidationPointsShare: 7355,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7355,
  },
  "0x9CDb231Cd70B7522c2b43ad18240649f9599F4BE": {
    liquidationPoints: 71271,
    liquidationPointsShare: 7351,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7351,
  },
  "0x898832C7b7A131E5d6C501A1aD791aa13e871fC2": {
    liquidationPoints: 71053,
    liquidationPointsShare: 7350,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7350,
  },
  "0xC15Ab8DD7DAeEa5F1aA3b56507CAc8A877E21b15": {
    liquidationPoints: 69914,
    liquidationPointsShare: 7346,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7346,
  },
  "0x9b815DD3e2D30CFEf33266fA4c6A7B37Bd99edFE": {
    liquidationPoints: 69847,
    liquidationPointsShare: 7346,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7346,
  },
  "0x32Af25467b00aAe516505B3331cfEc52092ea5BA": {
    liquidationPoints: 69046,
    liquidationPointsShare: 7343,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7343,
  },
  "0x0A821de645e9070196bFC9bE7ED6A8d7673737bb": {
    liquidationPoints: 68334,
    liquidationPointsShare: 7341,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7341,
  },
  "0x764f4909251F81976A0A2DCeBcE95333e4C27517": {
    liquidationPoints: 68056,
    liquidationPointsShare: 7340,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7340,
  },
  "0x34833AB677F5CC40A44A36330Fb18fEf2aC4F03B": {
    liquidationPoints: 67363,
    liquidationPointsShare: 7337,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7337,
  },
  "0xe41b70EDbb2f5708f48D1442C4935630f07D40F7": {
    liquidationPoints: 66843,
    liquidationPointsShare: 7335,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7335,
  },
  "0xeCe39f7Ac7603f450cCdf1c257d4a3eD29c4fEf3": {
    liquidationPoints: 65679,
    liquidationPointsShare: 7331,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7331,
  },
  "0xB47f61556eE7F1ad905F6A4B425c50d491c5Bd99": {
    liquidationPoints: 64750,
    liquidationPointsShare: 7328,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7328,
  },
  "0x81d6173C9607A1285F92325540C6b35D473802AC": {
    liquidationPoints: 64276,
    liquidationPointsShare: 7326,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7326,
  },
  "0x19eA47F28415d3288F8a96d8426f32f7166BE6d7": {
    liquidationPoints: 63786,
    liquidationPointsShare: 7325,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7325,
  },
  "0xE496FF96f62A871de4F4BDb14b4c5E21d9eAcee0": {
    liquidationPoints: 63575,
    liquidationPointsShare: 7324,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7324,
  },
  "0xDE261FBdF363F7600b29277D775cC265a8b5731f": {
    liquidationPoints: 60387,
    liquidationPointsShare: 7313,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7313,
  },
  "0x8f678841ebd0202Bc2E5c2d25B4170B5426abfa3": {
    liquidationPoints: 60149,
    liquidationPointsShare: 7312,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7312,
  },
  "0xe74B0B055E63ffF573697ec7329F89804fACF4d5": {
    liquidationPoints: 60093,
    liquidationPointsShare: 7311,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7311,
  },
  "0x9c8d7AFdd90bbB07e54a60291c5AF391d8AE6dfA": {
    liquidationPoints: 59817,
    liquidationPointsShare: 7311,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7311,
  },
  "0xC8160b24922664d9b3CF7f0acBC149dC33917d15": {
    liquidationPoints: 59198,
    liquidationPointsShare: 7308,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7308,
  },
  "0x1aBFa3D823f9F8D85AF33622046D3B7F66262670": {
    liquidationPoints: 58836,
    liquidationPointsShare: 7307,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7307,
  },
  "0x29F38498071b772a99bAb18C7ea4125387Ed2332": {
    liquidationPoints: 58753,
    liquidationPointsShare: 7307,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7307,
  },
  "0x0E9d6Ae20c982b43c219E9bEFd94ce2302264068": {
    liquidationPoints: 58430,
    liquidationPointsShare: 7306,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7306,
  },
  "0x767A60F295AEDd958932088F9Cd6a4951D8739b6": {
    liquidationPoints: 58585,
    liquidationPointsShare: 7306,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7306,
  },
  "0x67f5E368D28835A8f6B8E5576b1e17Ae2CE24769": {
    liquidationPoints: 58601,
    liquidationPointsShare: 7306,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7306,
  },
  "0x4335710E9C6774056a2932C05dd6Dd4de15818a2": {
    liquidationPoints: 58154,
    liquidationPointsShare: 7305,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7305,
  },
  "0x56b9272f84A9Bb4A8a00C614d77c5A3128A06154": {
    liquidationPoints: 58043,
    liquidationPointsShare: 7304,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7304,
  },
  "0xADD44fdf2f4807E350170404f2d69ca201044C70": {
    liquidationPoints: 57611,
    liquidationPointsShare: 7303,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7303,
  },
  "0xF1B3A985E3aC73dc81f8fcD419c4dda247d2292c": {
    liquidationPoints: 57506,
    liquidationPointsShare: 7302,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7302,
  },
  "0xEed09cc4ebF3Fa599eb9ffD7A280E7b944b436B7": {
    liquidationPoints: 56959,
    liquidationPointsShare: 7300,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7300,
  },
  "0xf6d812990ff95dc49d95fDDE90ce4f7CfdF1Cca2": {
    liquidationPoints: 55549,
    liquidationPointsShare: 7295,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7295,
  },
  "0x98289029B0d4bB73Ead9Eb1A3300eD724Fcf6779": {
    liquidationPoints: 55061,
    liquidationPointsShare: 7294,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7294,
  },
  "0x74A5B346F2cccf06e2127EB6680389E7D8953c80": {
    liquidationPoints: 54130,
    liquidationPointsShare: 7290,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7290,
  },
  "0xFF15208Bb7bdc9024431c3294C9D1f6E262304a9": {
    liquidationPoints: 53786,
    liquidationPointsShare: 7289,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7289,
  },
  "0x948005DefE7e1AC618A7CD53531852Aa3Fe3C877": {
    liquidationPoints: 52657,
    liquidationPointsShare: 7285,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7285,
  },
  "0x4612f6997fFd8a81E0E4CFC8Cdb62796d86CB082": {
    liquidationPoints: 52135,
    liquidationPointsShare: 7283,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7283,
  },
  "0xB1b89662a74d89F55Da3268E2006B059B51eF249": {
    liquidationPoints: 52181,
    liquidationPointsShare: 7283,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7283,
  },
  "0x52cc1bc45D387a52cE48A9c051614a6265529BbA": {
    liquidationPoints: 52107,
    liquidationPointsShare: 7283,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7283,
  },
  "0x8a4a50B13Fd2cb36FeB96c408CB98B4c9F2b8F25": {
    liquidationPoints: 50481,
    liquidationPointsShare: 7277,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7277,
  },
  "0xCd4EF8cd33A4EF7A59B368E1a72249B5eb5C4eCe": {
    liquidationPoints: 49105,
    liquidationPointsShare: 7272,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7272,
  },
  "0x0cD16F3F840852b17dB7f47C270BBd1a9D082BF3": {
    liquidationPoints: 48664,
    liquidationPointsShare: 7271,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7271,
  },
  "0x47eA7ccD0e3ad512966D15347c14102983491Ea0": {
    liquidationPoints: 48794,
    liquidationPointsShare: 7271,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7271,
  },
  "0x698fa9d90a61A190648CbB9e0e938bA0735e32a8": {
    liquidationPoints: 48394,
    liquidationPointsShare: 7270,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7270,
  },
  "0x66B259B9E072E1ae256e738Dac2EBc25044178DE": {
    liquidationPoints: 47927,
    liquidationPointsShare: 7268,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7268,
  },
  "0x3877b93D5411bf5F5CB92D7e7650935FD0212D8a": {
    liquidationPoints: 46566,
    liquidationPointsShare: 7263,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7263,
  },
  "0xE5508496e06583da360670Ce4AA4024a01E9861B": {
    liquidationPoints: 45422,
    liquidationPointsShare: 7259,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7259,
  },
  "0xdb70b7Fd780Ec7D588B658801d1DD53188B7b1ad": {
    liquidationPoints: 44620,
    liquidationPointsShare: 7256,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7256,
  },
  "0x215AE5E25647DADa54573c3de6924d8dC9F77ca6": {
    liquidationPoints: 44379,
    liquidationPointsShare: 7256,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7256,
  },
  "0x2D6B114c77F3355F204c37F657c680157e85dFA5": {
    liquidationPoints: 43819,
    liquidationPointsShare: 7254,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7254,
  },
  "0x24aa1Ad305F8e6dE8E0fA2F4c249AC278FBa9D7F": {
    liquidationPoints: 43869,
    liquidationPointsShare: 7254,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7254,
  },
  "0x44Ef4e21490D454d2D9e2B5Fc32b74c384d51744": {
    liquidationPoints: 42598,
    liquidationPointsShare: 7249,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7249,
  },
  "0xA05B26f366035F4A797c12cbC23B87633F353337": {
    liquidationPoints: 42640,
    liquidationPointsShare: 7249,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7249,
  },
  "0x44a03946C8e690c6Ecdb254B3744690A42E1ED17": {
    liquidationPoints: 41956,
    liquidationPointsShare: 7247,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7247,
  },
  "0x1B8a465eb476Eb80fC0C709F2f53A0eFDe46B1F5": {
    liquidationPoints: 41860,
    liquidationPointsShare: 7247,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7247,
  },
  "0x78eD05a4A2817400FA35C678A9b850523654454e": {
    liquidationPoints: 40901,
    liquidationPointsShare: 7243,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7243,
  },
  "0x590AD2D99D55AD698637AB8cb691a240a93B401e": {
    liquidationPoints: 40250,
    liquidationPointsShare: 7241,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7241,
  },
  "0xFaCEc1c32AE56097866A6c1dDA1124f2C6844F40": {
    liquidationPoints: 40218,
    liquidationPointsShare: 7241,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7241,
  },
  "0xDf5fa32B726a5118281e74aD3B7C707423e28F8B": {
    liquidationPoints: 39573,
    liquidationPointsShare: 7239,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7239,
  },
  "0xB4C526925CF2a25E1cD0406bf8aA36696D5E17D2": {
    liquidationPoints: 39752,
    liquidationPointsShare: 7239,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7239,
  },
  "0x04148d6CE66cbb2F5c198c272B8117a5e7a6102E": {
    liquidationPoints: 39545,
    liquidationPointsShare: 7238,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7238,
  },
  "0xb5F2949aADDd62C07AB10FFd39964fAF60887560": {
    liquidationPoints: 39273,
    liquidationPointsShare: 7237,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7237,
  },
  "0xada3f268965c7410dfa0d74b928D950D94fAa554": {
    liquidationPoints: 38767,
    liquidationPointsShare: 7236,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7236,
  },
  "0xe88857D67CF8E0031cA1674fFE17f72578e566eE": {
    liquidationPoints: 38567,
    liquidationPointsShare: 7235,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7235,
  },
  "0xA98FA4D599cBeE237cba6E8AEA50B3E0454598a1": {
    liquidationPoints: 36997,
    liquidationPointsShare: 7229,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7229,
  },
  "0x628D896988f2C56921F09dF2AED3549CaC359774": {
    liquidationPoints: 36978,
    liquidationPointsShare: 7229,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7229,
  },
  "0xfB07D2bdef2Deb1eBeC001644b4732AB77281C7A": {
    liquidationPoints: 37006,
    liquidationPointsShare: 7229,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7229,
  },
  "0xc95cb2BCac6700f5439ba9B73316755C4d7e396b": {
    liquidationPoints: 36204,
    liquidationPointsShare: 7227,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7227,
  },
  "0x9bE03894B73Bfcbbb2A8CCbC68b592E37eF04161": {
    liquidationPoints: 36062,
    liquidationPointsShare: 7226,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7226,
  },
  "0x57020360F19AA928d7D4D4347a36C0D5dF2945C4": {
    liquidationPoints: 35780,
    liquidationPointsShare: 7225,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7225,
  },
  "0x101E9fbeA2347f0441056ba80Fa9b495bCC21d0A": {
    liquidationPoints: 35406,
    liquidationPointsShare: 7224,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7224,
  },
  "0x404AC1bDfE4cF148Ab16114B1F2Ab0A4fa505038": {
    liquidationPoints: 34261,
    liquidationPointsShare: 7220,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7220,
  },
  "0x057ca4a6e2C11565be71A8453a9f3f86b5F2Bba7": {
    liquidationPoints: 34343,
    liquidationPointsShare: 7220,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7220,
  },
  "0x229229284c0eA7C2A898765a5652fc03F0e35293": {
    liquidationPoints: 34228,
    liquidationPointsShare: 7219,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7219,
  },
  "0x1697dB5839e8F997b61A57D723b498D9d1003301": {
    liquidationPoints: 33892,
    liquidationPointsShare: 7218,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7218,
  },
  "0x9755e3d858a3310e9915e490104435ed4f0D7547": {
    liquidationPoints: 32976,
    liquidationPointsShare: 7215,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7215,
  },
  "0xFb9b19c5e69125a27ecB9D6f2cf01bF595040B49": {
    liquidationPoints: 32860,
    liquidationPointsShare: 7215,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7215,
  },
  "0x010dA5FF62B6e45f89FA7B2d8CEd5a8b5754eC1b": {
    liquidationPoints: 32747,
    liquidationPointsShare: 7214,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7214,
  },
  "0x2F7949779e18c18298C2F253E34F9355403a5c4E": {
    liquidationPoints: 31968,
    liquidationPointsShare: 7211,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7211,
  },
  "0xE0bBDEdCE4F9DD2387c670f65DAFFB981bDBb6d7": {
    liquidationPoints: 31381,
    liquidationPointsShare: 7209,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7209,
  },
  "0xBA1b35Eb69052aEF4CE95abC0c38e0Ff67410aEf": {
    liquidationPoints: 31301,
    liquidationPointsShare: 7209,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7209,
  },
  "0x8fEaFd5e0FCefdd2624906c0F913D563306aAe17": {
    liquidationPoints: 31230,
    liquidationPointsShare: 7209,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7209,
  },
  "0x7732E50A5bDc17578CFC9f289D843ec16b71572B": {
    liquidationPoints: 31403,
    liquidationPointsShare: 7209,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7209,
  },
  "0xc874901C1aa292781110118Ee4D3731c74E05b16": {
    liquidationPoints: 30003,
    liquidationPointsShare: 7204,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7204,
  },
  "0x4a64B2820a06A67EE878157a7f48611463D6bD78": {
    liquidationPoints: 29576,
    liquidationPointsShare: 7203,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7203,
  },
  "0xA198E245BF6929C1a1899E36BF957417143D4f7f": {
    liquidationPoints: 29207,
    liquidationPointsShare: 7202,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7202,
  },
  "0x643861ABF4386cB2f8f4d7bD49221389F675839A": {
    liquidationPoints: 28609,
    liquidationPointsShare: 7200,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7200,
  },
  "0x58C86E2EeF4c7C90b567c3Fb2E210c69977E260A": {
    liquidationPoints: 28536,
    liquidationPointsShare: 7199,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7199,
  },
  "0x0D128Baa514DbC2b7e6B2E4804Af7DA77Efa9B08": {
    liquidationPoints: 27729,
    liquidationPointsShare: 7196,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7196,
  },
  "0x6863edc8114928EFbFcBF23Dc0Ef211eA9cbeDDb": {
    liquidationPoints: 27028,
    liquidationPointsShare: 7194,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7194,
  },
  "0x496222654D12C7Df88ef90fEcdf8747D4636BEF0": {
    liquidationPoints: 26897,
    liquidationPointsShare: 7193,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7193,
  },
  "0xDe95dB2D1cca0210EF26B786BB531CfA660132Ff": {
    liquidationPoints: 26378,
    liquidationPointsShare: 7192,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7192,
  },
  "0xD663354A09aB4b0e67D077a540D77c2740E30290": {
    liquidationPoints: 26126,
    liquidationPointsShare: 7191,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7191,
  },
  "0xf519765F1CcF2d2522D316d6A5d1771e8972233A": {
    liquidationPoints: 26141,
    liquidationPointsShare: 7191,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7191,
  },
  "0xa86986d14D502e69a864dBcB0bA33B1910e56dF5": {
    liquidationPoints: 25620,
    liquidationPointsShare: 7189,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7189,
  },
  "0xA4b5741B8f0Eaac20fdeab43f345c4cA1cC4A833": {
    liquidationPoints: 25519,
    liquidationPointsShare: 7189,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7189,
  },
  "0x1bF99f65A112D0D38B249838AfB5C50321BBb50A": {
    liquidationPoints: 25005,
    liquidationPointsShare: 7187,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7187,
  },
  "0x11219d61e95fC5930762b16868dDB2C9c6fc83fA": {
    liquidationPoints: 24955,
    liquidationPointsShare: 7187,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7187,
  },
  "0x96Ea3317a62d77d66b857b323abF56d3c8acC6A4": {
    liquidationPoints: 24122,
    liquidationPointsShare: 7184,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7184,
  },
  "0x00409fC839a2Ec2e6d12305423d37Cd011279C09": {
    liquidationPoints: 24244,
    liquidationPointsShare: 7184,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7184,
  },
  "0xe5eF055d0C9504087e956f08FfC77EAD3cB64f99": {
    liquidationPoints: 23749,
    liquidationPointsShare: 7182,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7182,
  },
  "0x13Dd8a0756Ac6Bf0F5FeC3a27130e96c484D842b": {
    liquidationPoints: 23058,
    liquidationPointsShare: 7180,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7180,
  },
  "0x7B21CafE498eAe8697ffEF0be12F771db20f2A4a": {
    liquidationPoints: 23011,
    liquidationPointsShare: 7180,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7180,
  },
  "0x579C5CE071691aec2EBfe45737D487860eB6F3f5": {
    liquidationPoints: 22737,
    liquidationPointsShare: 7179,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7179,
  },
  "0xC86660CbB98Bd71119b85f9EF3930F3B70EAB38d": {
    liquidationPoints: 22810,
    liquidationPointsShare: 7179,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7179,
  },
  "0x1A92E2b2Db688D5Ce3d24b58dbcC7716912873D0": {
    liquidationPoints: 21627,
    liquidationPointsShare: 7175,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7175,
  },
  "0x30EFDa616DbD3Dd0893bbea28BF3fE451BdC9ab7": {
    liquidationPoints: 21802,
    liquidationPointsShare: 7175,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7175,
  },
  "0x88C57659E00DF3fFEA57d41539e360c6AA9C6177": {
    liquidationPoints: 21501,
    liquidationPointsShare: 7174,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7174,
  },
  "0xbE4896c3acB7FC415E2e69108333ED431Eb38993": {
    liquidationPoints: 21097,
    liquidationPointsShare: 7173,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7173,
  },
  "0x86691c1dD5D567f8e73A46fEb0ddD2b42e404b6b": {
    liquidationPoints: 21270,
    liquidationPointsShare: 7173,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7173,
  },
  "0xA7E6F51aF4fbD7d1F4d95764bBC965CeC4AD5b91": {
    liquidationPoints: 21261,
    liquidationPointsShare: 7173,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7173,
  },
  "0x1254757bF5b1BEDDc857CdDcd9C588a450C4427B": {
    liquidationPoints: 20678,
    liquidationPointsShare: 7171,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7171,
  },
  "0xb31d4303De85b107FEe62380CaC35C444c35d144": {
    liquidationPoints: 20630,
    liquidationPointsShare: 7171,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7171,
  },
  "0x9C41ea9390F47d7bcD48ea87e5b5463aF26791EA": {
    liquidationPoints: 20010,
    liquidationPointsShare: 7169,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7169,
  },
  "0xd3faB52A0C93346F23a9b9b24Fd45ea0b8dD3420": {
    liquidationPoints: 19844,
    liquidationPointsShare: 7168,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7168,
  },
  "0xAA1C2195F2d7ec7d2683Fcec2c54445a16ADBa93": {
    liquidationPoints: 19376,
    liquidationPointsShare: 7167,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7167,
  },
  "0x102e51c706eaf0293715aF73C99F2A8589a2084d": {
    liquidationPoints: 19365,
    liquidationPointsShare: 7167,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7167,
  },
  "0xbDf1301D0DB4417F985eBb04D9cB4A05AE07a9eF": {
    liquidationPoints: 19578,
    liquidationPointsShare: 7167,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7167,
  },
  "0x22FBF2DDC6a0d6Fe4f69dF0b0e61939f6a2411b9": {
    liquidationPoints: 19389,
    liquidationPointsShare: 7167,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7167,
  },
  "0xB7Bd892B95fc2FaF5659404D9Aca1bfA164f0686": {
    liquidationPoints: 19050,
    liquidationPointsShare: 7166,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7166,
  },
  "0xbD84c4e420a22D7639ec14CeEDC39aaBdcb2094e": {
    liquidationPoints: 18817,
    liquidationPointsShare: 7165,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7165,
  },
  "0x53E0819372F5Fd5eb1650884EbDeb61b0183Ec78": {
    liquidationPoints: 18916,
    liquidationPointsShare: 7165,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7165,
  },
  "0x9fF77702A50A2962F6520Eb4Db285827f9FD265A": {
    liquidationPoints: 18505,
    liquidationPointsShare: 7164,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7164,
  },
  "0x9BD215C2530dF574657510B2F991d5C326df7C50": {
    liquidationPoints: 18640,
    liquidationPointsShare: 7164,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7164,
  },
  "0xA2D35E418814A8b6367c66a5ac2FEc8ea16332b2": {
    liquidationPoints: 17807,
    liquidationPointsShare: 7161,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7161,
  },
  "0x976FdC5DfA145E3cbc690E9fef4a408642732952": {
    liquidationPoints: 17596,
    liquidationPointsShare: 7160,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7160,
  },
  "0x625aB39153fE8e0F9d53ADE43a9d9C71Cb7bB859": {
    liquidationPoints: 17407,
    liquidationPointsShare: 7160,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7160,
  },
  "0x999b98242f5384E225a02aFceBb5Cfc817559e65": {
    liquidationPoints: 17061,
    liquidationPointsShare: 7158,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7158,
  },
  "0xA0ebF4f41BA800A534883DaEb0b661029986385c": {
    liquidationPoints: 16547,
    liquidationPointsShare: 7157,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7157,
  },
  "0x59344873Ac5ca0433ca379e06370eBd35952672e": {
    liquidationPoints: 16622,
    liquidationPointsShare: 7157,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7157,
  },
  "0x4b5aeEb5fd6b9D7FEbF8AeC97bF5717423f8CC89": {
    liquidationPoints: 16638,
    liquidationPointsShare: 7157,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7157,
  },
  "0x9bE6e8f7dF4032B73EA2Fddb633ff13298B46584": {
    liquidationPoints: 16460,
    liquidationPointsShare: 7156,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7156,
  },
  "0x84C650F7E873994c7518359102F9eeC5B240061C": {
    liquidationPoints: 16470,
    liquidationPointsShare: 7156,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7156,
  },
  "0x698BB6299824a053A9Fa1e677d48ca44DaA0a0c8": {
    liquidationPoints: 15960,
    liquidationPointsShare: 7155,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7155,
  },
  "0x3239a95A9262034ca28b9a03133775f716f119f8": {
    liquidationPoints: 16008,
    liquidationPointsShare: 7155,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7155,
  },
  "0xfE5F7Be0dB53D43829B5D22F7C4d1953400eA5CF": {
    liquidationPoints: 16077,
    liquidationPointsShare: 7155,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7155,
  },
  "0x1721e0Cb83bD4b504CD30d0DDA2dd79973d725cc": {
    liquidationPoints: 15441,
    liquidationPointsShare: 7153,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7153,
  },
  "0xF3E16a28b09bE3836e66f091613c230128141Fc1": {
    liquidationPoints: 15565,
    liquidationPointsShare: 7153,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7153,
  },
  "0x37223d721901EC493906ddF800B71dbCB347fa68": {
    liquidationPoints: 15591,
    liquidationPointsShare: 7153,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7153,
  },
  "0xd894de705b1fA1548C8C7Ad1B827b32f6CA36475": {
    liquidationPoints: 15247,
    liquidationPointsShare: 7152,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7152,
  },
  "0xe2894FD6cA974d7d248010Ac0Fa98eaA27Ed790A": {
    liquidationPoints: 15376,
    liquidationPointsShare: 7152,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7152,
  },
  "0x596cb04604C2fe7b2c5834d93226c6a6fC11341f": {
    liquidationPoints: 15064,
    liquidationPointsShare: 7151,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7151,
  },
  "0xDda071e39aB81Ce6a093a23C136E24374e94353e": {
    liquidationPoints: 14831,
    liquidationPointsShare: 7151,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7151,
  },
  "0xcB15649D7B7a1eb01fb1ACD14809de6cF82Aa1A0": {
    liquidationPoints: 14328,
    liquidationPointsShare: 7149,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7149,
  },
  "0x6D5Be0208eCC2e028012aA2A050D657c015a53B5": {
    liquidationPoints: 13998,
    liquidationPointsShare: 7148,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7148,
  },
  "0x865D7eb5db37cc02ec209DD778f4e3851a421A20": {
    liquidationPoints: 13842,
    liquidationPointsShare: 7147,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7147,
  },
  "0x36fB3C8D2174285888A97C6F398a8bA91673eee4": {
    liquidationPoints: 13733,
    liquidationPointsShare: 7147,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7147,
  },
  "0x7C5B7Bf87Feab9A3F4972323BDdDA04C79D61315": {
    liquidationPoints: 12890,
    liquidationPointsShare: 7144,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7144,
  },
  "0x74a40ACb2819f648BB0b9F1edE9b112Ab8031ade": {
    liquidationPoints: 12336,
    liquidationPointsShare: 7142,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7142,
  },
  "0x547b324B3f9E1f9f436fEde6E88AE1CA816dB6F3": {
    liquidationPoints: 11805,
    liquidationPointsShare: 7140,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7140,
  },
  "0xff2cE9D334df35Ed9FCB155F33856b638Ec5D3a5": {
    liquidationPoints: 11567,
    liquidationPointsShare: 7139,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7139,
  },
  "0x2B5b64dF5E31A31d2E48dE94b15c2093bC4CC09C": {
    liquidationPoints: 11321,
    liquidationPointsShare: 7138,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7138,
  },
  "0xb20aab4cd244CF2645Df2cdfBcD98bA7E2395927": {
    liquidationPoints: 11237,
    liquidationPointsShare: 7138,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7138,
  },
  "0x1674E429679f0F494eeE69Bc6C133369B07bFc71": {
    liquidationPoints: 11371,
    liquidationPointsShare: 7138,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7138,
  },
  "0x7914b1F3539B6aa09812d1026E9C11ECD4Ff6df1": {
    liquidationPoints: 10660,
    liquidationPointsShare: 7136,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7136,
  },
  "0x90A23Ca50c31161C5297BA9Dd55C70212AB056a7": {
    liquidationPoints: 10643,
    liquidationPointsShare: 7136,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7136,
  },
  "0xC6569e8eb9fE5D786bFaA0c207a3f37345e68Aa2": {
    liquidationPoints: 10475,
    liquidationPointsShare: 7135,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7135,
  },
  "0xCB04F9F5E1Ac544dfFb0d479b696e7AAdb76dBFB": {
    liquidationPoints: 10523,
    liquidationPointsShare: 7135,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7135,
  },
  "0x7d30Db5b2eDAB43F7A98645E9a6a0e4806d29aE4": {
    liquidationPoints: 10219,
    liquidationPointsShare: 7134,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7134,
  },
  "0x1341df844780b66AF4ccC98Ae0F34be87eABe1d5": {
    liquidationPoints: 10232,
    liquidationPointsShare: 7134,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7134,
  },
  "0x5A805112171FEa540cb85f851757E395751FB7C1": {
    liquidationPoints: 10165,
    liquidationPointsShare: 7134,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7134,
  },
  "0x1e39cC29009ED4760c186d8771295c1E224882d4": {
    liquidationPoints: 9203,
    liquidationPointsShare: 7130,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7130,
  },
  "0xDE548f920b518039C0dDD37036bF6E2b95974E66": {
    liquidationPoints: 8715,
    liquidationPointsShare: 7129,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7129,
  },
  "0x58C4f03a954e4CbB1b8E204a881a8e9A99d015Dd": {
    liquidationPoints: 8544,
    liquidationPointsShare: 7128,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7128,
  },
  "0x39a28fb669306d17310A0e0CAB5B8312738cb1E9": {
    liquidationPoints: 8189,
    liquidationPointsShare: 7127,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7127,
  },
  "0x9b51D8a0809F06034d36309A0493b01bdAA86c5A": {
    liquidationPoints: 8288,
    liquidationPointsShare: 7127,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7127,
  },
  "0x302bA634D463B3C3B6d8Aed233Cde9efec10C327": {
    liquidationPoints: 8247,
    liquidationPointsShare: 7127,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7127,
  },
  "0xaC749eF37797F963acC59146E1D2F4015E97dC79": {
    liquidationPoints: 6729,
    liquidationPointsShare: 7122,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7122,
  },
  "0x01b4868d489C7E3b931730f316ADaDF739409c98": {
    liquidationPoints: 6537,
    liquidationPointsShare: 7121,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7121,
  },
  "0xb24678ef7B1E1fAf9E1A452c1e7d337431E8c783": {
    liquidationPoints: 6479,
    liquidationPointsShare: 7121,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7121,
  },
  "0x752cdfDFfAeebe73BB1388F69f94553adE64C988": {
    liquidationPoints: 6414,
    liquidationPointsShare: 7121,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7121,
  },
  "0xA7A72428C08F29F0F1b06e1586E4fc40bC914400": {
    liquidationPoints: 6580,
    liquidationPointsShare: 7121,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7121,
  },
  "0xFfA13dAf5b32E44758A5c4882f4Df2612c0311B2": {
    liquidationPoints: 6581,
    liquidationPointsShare: 7121,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7121,
  },
  "0x79E43018b8B1f39480B8E771724D508460b22461": {
    liquidationPoints: 6405,
    liquidationPointsShare: 7121,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7121,
  },
  "0x7e5C1A665BFD595a8dE3aC91dabB37a65dB04096": {
    liquidationPoints: 6262,
    liquidationPointsShare: 7120,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7120,
  },
  "0xCE00CA9E1675fE532Dda35a8784beF9f276a0165": {
    liquidationPoints: 5852,
    liquidationPointsShare: 7119,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7119,
  },
  "0xA38a958664d44F99B4d4747aAF4D6d52631A222a": {
    liquidationPoints: 5933,
    liquidationPointsShare: 7119,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7119,
  },
  "0x63137E08CdD273574E6D4Fb95149257925c66Cae": {
    liquidationPoints: 5646,
    liquidationPointsShare: 7118,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7118,
  },
  "0xdd06c215CCB91AAca697f565B1BaEE8A95Fe8034": {
    liquidationPoints: 5350,
    liquidationPointsShare: 7117,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7117,
  },
  "0x00929C5c2c4f00B540e429247669Eb6fcd8b1DBF": {
    liquidationPoints: 4920,
    liquidationPointsShare: 7115,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7115,
  },
  "0xBe9F2e6537Fde4c7b010754c83ef6644b6eb9994": {
    liquidationPoints: 4675,
    liquidationPointsShare: 7114,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7114,
  },
  "0x7fE5235532453aC0Ee8e2B55A0e9e409954bB169": {
    liquidationPoints: 4276,
    liquidationPointsShare: 7113,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7113,
  },
  "0x50d5C3deB657E2FE1b70574c697655Ff99b72CDb": {
    liquidationPoints: 4245,
    liquidationPointsShare: 7113,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7113,
  },
  "0xf9e1D1e9F22c96752356AdFd377231528c7E851E": {
    liquidationPoints: 3926,
    liquidationPointsShare: 7112,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7112,
  },
  "0x00803232cC216eef9b396D6CfFFD4b51E09F29da": {
    liquidationPoints: 4013,
    liquidationPointsShare: 7112,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7112,
  },
  "0x90AA5c7a67081409d0b826faCD34600463778a48": {
    liquidationPoints: 3080,
    liquidationPointsShare: 7109,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7109,
  },
  "0x55A5705453Ee82c742274154136Fce8149597058": {
    liquidationPoints: 2591,
    liquidationPointsShare: 7106,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 7106,
  },
  "0x0210798166BC4934Ffba40837cad2fF589056FFE": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 3572,
    totalShare: 6072,
  },
  "0x9776a4A25E08230aA132865D5FCE43Bc48f0Eb18": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 3572,
    totalShare: 6072,
  },
  "0x688B5c7B549339922C0D2243debE2Fe921A1A488": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3572,
    totalShare: 3572,
  },
  "0x850596444Bb1921eF60E647F0951F766c6359C83": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3572,
    totalShare: 3572,
  },
  "0xCa41A5FccF73D0793BdF8657ED4010ec40F10F2C": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3572,
    totalShare: 3572,
  },
  "0x82D1883ca96e57773429E785F195E32783B1C246": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3572,
    totalShare: 3572,
  },
  "0xF1b98463d6574c998f03e6EDF6D7b4e5F917F915": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3572,
    totalShare: 3572,
  },
  "0x252BD5DCF0AAf25F3754b65BAC25a891321F02c6": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3572,
    totalShare: 3572,
  },
  "0x33d84d2a77d850b13053a1496d25cC58B145aa13": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3572,
    totalShare: 3572,
  },
  "0xE1DCf8ae4d74c45fB7c9d386b9F1d488Ef1ef5ad": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3572,
    totalShare: 3572,
  },
  "0x75f2aA8424867FAdA68fCBBF07611F91C903b743": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3572,
    totalShare: 3572,
  },
  "0xeA4007a31D9a81C52C5A5106DFCa203000E4E885": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3572,
    totalShare: 3572,
  },
  "0xA1830E8d9F019FEB448478a171Bb37Cc6C4c0482": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3572,
    totalShare: 3572,
  },
  "0xa2AbaD715aaF5bdd9Df426e02eb1F28b4CB25034": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3572,
    totalShare: 3572,
  },
  "0x537bf75de19f3d229e3a9018EE1A23c0c9C7D39C": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3572,
    totalShare: 3572,
  },
  "0x84ECB387395a1be65E133c75Ff9e5FCC6F756DB3": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3571,
    totalShare: 3571,
  },
  "0xfd22e70cB42a0fa315C82e0Aa95A6Dc19f8B4934": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3571,
    totalShare: 3571,
  },
  "0x35d2fC24A1d8B6Dc019ee4e0e382CE4d394b010e": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3571,
    totalShare: 3571,
  },
  "0x6c2693F5a936f37eD03CfA8465bF2D8BEFf19A0f": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3571,
    totalShare: 3571,
  },
  "0x21258055dfd7a287DCC224E3586210F1864c1996": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3571,
    totalShare: 3571,
  },
  "0x8b501A6090Fd3c6b905B1A0099E2E216a5a2D40d": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3571,
    totalShare: 3571,
  },
  "0x43743b802F7606078EE5B1562386177793558ceF": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3571,
    totalShare: 3571,
  },
  "0xa477416aAfEDd065F374AEEC0E25f2910Db7Cadf": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3571,
    totalShare: 3571,
  },
  "0xC060bC9CB27ebCDe1773f0D0fdA444b509038b05": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3571,
    totalShare: 3571,
  },
  "0x660f95e3D6fC0a67cd6226A76bf7CF31A1c2a6Ce": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3571,
    totalShare: 3571,
  },
  "0x80fb5880F38185661962E475ac1557817dc9faea": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3571,
    totalShare: 3571,
  },
  "0x8136Efbaf75D7b13F033573666f393d65Da35953": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3571,
    totalShare: 3571,
  },
  "0x54c375c481f95ba43e2cEcd6Ef30631f55518f57": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3571,
    totalShare: 3571,
  },
  "0xf050Da6c5778E4ac9208C168744B2f385F31cE52": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3571,
    totalShare: 3571,
  },
  "0xa75F9C8246f7269279bE4c969e7Bc6Eb619cC204": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3571,
    totalShare: 3571,
  },
  "0x527A3ab8f1ff9172fD7d380863c54EdEc60bd41d": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3571,
    totalShare: 3571,
  },
  "0x9eBd10B46B43351097caB2D3c03Ccf440957A2a9": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3571,
    totalShare: 3571,
  },
  "0xEB4576fE753DAB07635c0Bb6c8f0A355e1Db5d31": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3571,
    totalShare: 3571,
  },
  "0x11FFDe6fdA405B5ebd93e5Bc4fdc0E30ada035D6": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3571,
    totalShare: 3571,
  },
  "0x3fb5EB6F0D81Cd51ADb5338cF93bb836838A5278": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3571,
    totalShare: 3571,
  },
  "0xf6d79DEC527467C60e6CCE69193829522B8438a1": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3571,
    totalShare: 3571,
  },
  "0xaDaE0Cdb5b7fdbCDb6094b80aF96869Abf8aD394": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3571,
    totalShare: 3571,
  },
  "0x300F818133Cb321b1099cBD845044390F37e2E22": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 3571,
    totalShare: 3571,
  },
  "0x24DE22BBb10a7fc344E12557B131dB77F6E5dAFe": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0xff6B53253f2E802258138a48Fb26736cc177090C": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0x57c94DB73ae5918754528eaDA385f7FC6D64B00F": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0x1eAc87b51AFaBBa0d40e7f207E96F1943D149ED4": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0xe80C7AcDC576570B04e3B53EFa323A80fd68FDfE": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0x1df29168D7150D918eA16f2c6f2Ab4e04735B849": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0x910C66F3481143dF94771034236e9c944D6355f1": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0xb0Cf1453A14c433d95a1b2eAEf9c3BDA16d7d326": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0x27e32Ef9576dc05F4D08d978106fC16351d4cb0c": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0xEB5C95fea4835EEFFD7De853f3405c5fD758DF1D": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0xB8b5DE38f5DdC55b6904c09bFe1B278532D63A03": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0x927Fd8A5Da652936e6CcD300832DC3CF2D0951B8": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0x09dF735f84Bbd1E699489BaF27d4D9e6D07545C6": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0x221b172eD6E5fd28142B42DD2934c094Aa561cB6": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0x7Ad004620634497Ff3de0fA057d2371f81E88c26": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0x1B32fc9b7fb1D32Cb48D93A3fF22B8850acf33B1": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0xA4BeB4fbe651b00bc16d472e2E1aa1C26b3D90aa": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0x1033Fc0D577a98e16300685da83A0f2DFab55942": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0x9ab32366Dba383fd7d436e71CC790BB8977Cb8D0": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0x559bB62c14aAA21314195812970DE5a59E8AeD40": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0x9f6983D2E2C2fbD7715B9Ea9972BD01a2C7e4700": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0x6998d00aED60A31A393A4BA9D2891e7Ba141B1E0": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0x6091d2E764fdC806677dE5E808A40eCba0F18182": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0x427590364963E67442bED627b2a1485B7fb76D3E": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0x6d45832AB01b75e8C928242e849a810cA911f5eb": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0x308b07f6c8e7Eb092Df161bA936D1Bb9e5542803": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0x6DA62967e52BDc3f458F93FFa37FCcF007863269": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0x27545eB2be12eAF146CaAB5f2436FC933AfA57a5": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0xa3B0FFB26f98aC163323571E1B9f77E4dD19714E": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0xAe5adD960EbFD1c345bb7a2701f2E7bEb832563E": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0x4918bc5204930b6620728cbc1F7577c54155c976": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0x8c1c4fD37bBcE8e321B3f134A0eEdAF1fB30ce65": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0xeB8F7b8C27732e00beF318441422000D8CCd1e54": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0xa7AbE1c2Dc27f2ac6832422Ac6fe887E1410dF4B": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0x3d909b6cB958a32f1A6e0D016C387A3a2C8DeCd1": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2831,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2831,
  },
  "0xb034c88Dd18504C39177341BE50c1b277EC3526d": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x553713f005a9Bf2d813f38195036C8f26bd6eAb4": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x096158b8876C0c5c3Dc01aC033e35265c9CD116C": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x7B92E1c1D11507c9A3d9aFCc782EcC146488951b": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x6B58cB696a4e76BAc85196040a8A95CC8dC8c461": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xe8c4ef2478a13F8158ACD7B24F7953F48F7B4c66": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xc89ee38a6ae90cBd23E911296803643219CE1900": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x637Fed24D31822d36a74FD9e35fa5b9F05820EF0": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x11B8b9a116ff95958183168A0c20aA8c506Ce503": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x585639fBf797c1258eBA8875c080Eb63C833d252": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x4F959F60f7dEf04b3d0ab4aA95bF5Df226B81F20": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xF37D3f15814E7685883CB88A42501da54c77Ca83": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xA8DAfef1568b397CBf7214c4814FaA39033767d0": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xd777D3971Cf9bccef65B0B665f0e4A5e9d05C041": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x15CF0aDfa8c55a1312318D100903850aDD78D3Ec": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x5051E2E894657c09cF3F3CcEE2A06bF24354e256": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xA74eB9AE9BC36372dF4231efA3D4f775f177C47F": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xC04d6DB6CFb0e7E0366fC7Ec9945A51967fE14E5": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xF51813A01a04C9Ba934601763dC9887A10E97e89": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xcf493D92A8fF7e8E938E29196780a4bbb80daa7D": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x53A2d092eF09Bd9bDc6Bc958DCC7a8B0A7dc9482": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xb747f20b7B729d385a844ed200e8Ce914cEFc9d8": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x9434e8C9Cf7d08CC36338872C7F1F0f75B036039": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x42f107da772Bff3c88574bFb71b2E286f94Dd207": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x7C8F39f35Ba890Be972D2CcABab0e3Dc99A82001": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xEb4BFB63797ea143df64Ee8c9a5A3f5024854241": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xc6C8AeCb1d9cA59A09d691cDa8539992655dbfB3": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x581c9c9F72DefeF9e2B1330CbefA804844395340": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xdD071a52769519beF4a704301444d6296Cc56E6C": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x59a0229d44e270D9079A9643FD2aCE4e08eA240C": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x3Fb8b40543cAa7C0cb73dA99cf2544983455457F": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xe09136166C1B56FA3ef732872673ec3092031332": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x78D5E7E5b4351878De24b3908CBC41084De801a4": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xe447F8cdb7BcB956B55cEDBe1290f599Da6016ff": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x33Da03015D376372303f3f38f31D483C36CE6883": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xAee33D473C68f9B4946020d79021416ff0587005": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xC6236AdAd15cE2941F61f6d8d5814D77D83452F1": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xdd111f16dFf7715a9506E54Deb957470BE0aE8D8": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x61ABf360FEe2b472b8E6dCD94215120259A95Dd7": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x3716905b040D5cd8a7DdE784918199117d72c3B3": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xC3dE461046a2CF4592840e08Fb89e79E79232d1A": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x6A45710188Acc25AD494BDA80E877073E9616e13": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xdFFCA56A7df6cA18ede7Bc5Dc5E2402bC5D13d3A": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x101fc51A292517932da6737916eB5Ee3604Fc40a": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xA0B67e1BFA11F1Ea257BDe2bB3CB89822aEdfd23": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x4BAb23Ebb08679D2222c1b971Af486Aa8A20d06d": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x4362a29E330427D951f1aa58D7E3E86aC9e4b4e1": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x2880A923F329e1520621781317D043fE68Fd347c": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x72eb41AE7f943445Da9932662d31A06D56A3cfAa": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x3E37573F4faC76EbA41d9D44285484498fc6370B": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xC209D40fAD6d9415a43CA7b123e65c95aD463A4c": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x1709735EFb831Bf0f9BB3794Eff8afF175068a76": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x2536eE17BF914cd6D72ec9CBf9502dE9F1bbdFDF": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x5aCe7B321eA55b54f7587281D3cF7025C2F20b9E": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x283E6c64EB2F45b7bC1d18329a659c4332012b75": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x3f5B738c9Fd400B6a75E59c3028dF58b59ae6B91": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xE1ecD60Fc5cdF64F90F07f6296Eb3d641a15D6Da": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x24651ced5Eb12D6822d296b5D5e4aDc492dC83AB": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xBA5E00B0d91fA0a0FA40B704D00D01cB0c8008c1": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xfC683e9C2BF35347db50Bb01977630d080cB5C21": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xC7E396D9e60E10AEf0C57A7F750Da90DE02352be": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x12554d6583B1479e665016D5FB2F3527528dCC71": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x638DF4BF46f107cB06F9D243E2A9ae5Ba680bBf7": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x465446fe894dFD350627292490beF5203CaE4C8A": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x357C8F328728C9c22749D080f2637Cc5396C1324": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xBe5d3479c460De1F81cAfBc7563a908Aa8f5691B": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xCD252d6AB26b7363E75d7451029C0f0729783AcE": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xCcDdf5d6c749C130e0Db9e61027Cbd036bA9B11E": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x0138764276E1AD86333feF83DCcf25B2C475F083": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x788eB8F5DD1D79074E30a44f7964c1df0688299E": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xf47988e91a85294a10E08E4060110FCF91c2620B": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x0bf5B319A6a683f1DD942edcCaC40eE51Ed0f5aa": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xf778Cd536Ea0826a3007f3A29Dc83fdE7fe93E22": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x722f247A3e9ca11E349db9d000Ae8d3892C57686": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x476bC377f1fd831535443e21fd9E8A151F6eCD08": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x157C17356DD02eCbF78d7328000E8A4d9C2BC440": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x096eDfE03c04d91D1F297662092AC1B336e6D427": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x4F4C3a3AB3423866849B986B60Eb53Df2E8602E4": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x82a1844A178A217465b4cf0B72A473C3bAaf56EF": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x1783CFc786977144012D2752819542f563832e3F": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xBBAac64b4E4499aa40DB238FaA8Ac00BAc50811B": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xaEd0BD15E6B8fd728b2c3652453Ef61d3Bde2A0C": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x43843F8eF88b1E10489f3285286A29453F2e2aA8": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x806C48C64e23E2D32dAeFA5034Daa9Df4554cC64": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x53D35816aB311472812eEf183d1e83a3F3e9F4a4": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xA39e1B0C4A8111BA2EBD79c854f07bD468368e3f": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xac798Df80b371569Ec73e37124fFEf7e26249667": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x0071444089128f961f52a9B9aDBB795878244bDd": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xFaf6bb8F1a300130fB9dc582DC8363a50841882D": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xAb9119555703Be8E13A511972271Aa800D8255c2": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xa94fdea84c90670d0E2D479c845B5B293A63f1F2": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x18a9Fd5D2E3E30aF55AB3A83aE9269C2A70f1310": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x5193C5e92a02Ef47B7F9Af5c497266F0B66B0576": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x8579F7795d67E0B6019dA77B132D94f04E1aC911": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x2680616646C4512e13416546385Fde07CC10fa7F": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xc611DadEEb92F651a930bD0004f15D9746B7bE8D": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x00779b79BB1Ec71CE36340c1128687C61cFb0feb": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xD46f07a728228Dd2c61E929499cE8ABAf85e86C0": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x00008e511292F1f42667Cd99239127cF4CF0422A": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xB177708a08a079cB48b8C8E35F869aA00B4e0d26": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x38A63519a8EE25A074c98394764Cc10db8175E00": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x264827B8DD1cA3EF6afB080241B3D3D8dE8178B3": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x926Fc02C90EfeCc0085320fEe1437A1CC80A776f": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x3E5e7B957EFCA8FD26B5B510C5c716AB514F8c41": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x88866A2d2d244d3aD333D66c60191095769Ad0b4": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xA3b1Ae895b114eE3004C2b9eD54574791b07097A": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x4F06ed717A8524860E20A269157a423fa54F393E": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xDFD5a39751A5c810f0Ec94a0B5aF8897A0BdE215": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xA614aed865a2537e6100f34A3FAFB72cB8AD76a9": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x6e8F26d35800EbB7678ac858866EC0e86Ba7D1FC": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x486FefA50C7D148F5a22950e36ED28FFACB3e9b3": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xaF9c74c0f06CA6bF5E545bB69a32c3fB8f2430CC": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x3994537274f3fF3eefc413E0D669b05D6446D46b": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x82A214B23C0119F0C37bE360889927D68C2cAab6": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xd96a7311D90B15C5AcA80e994820330c0C1cE875": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x44c2130c18cce60fC9D3D2F99FCF95301517faD6": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xA7C4c76afC0ea8b370c2ccB6a66be947733A60d0": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xAc9752012724c9cd47217C5dC75199cb1EB64c3d": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x65b8ee88Cd729b00c574884f3404678bAc479dab": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xD2fB35bAdbBb7982d3096F8aC01F3Cc963652F06": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x0DD7D73Fd2aD707e7bFDde4805fD5cB9d6Fb7B7b": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xA59A29C8d56b22a17DC6c263A7E0af1BCC2934F4": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x95d4b05A61341592Dd0bFa6fd0dCBEbBfd0B5Da9": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xa5Ff581206EaC635E450D99784e3Be4d8395a800": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x1Ccf968217dCD3FaD42029115Dab5d329d9F32ce": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xB4D3c81418A32b6c8DbB6462bBED26ab16884E92": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x48e9E2F211371bD2462e44Af3d2d1aA610437f82": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x33d3cad5b003aE22Eee3E2f4bF014539efbD5347": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x510A4cBd16B65ddc32E3392B246Eb19005332a80": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x534E3E7979f220E4342218514C8AE7CEcBB89e7a": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x130FFD7485A0459fB34B9adbeCf1a6dDa4A497d5": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x73bb185Df8c7Ddc130F594E0fCDc0297658B51CF": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xa785Cac40382B8e5D264103cF57AcaA94d3E5BCe": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x33F1Ae9733ba0d20544940aF19d7113d28404616": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x7E4a268c3060dbf7933Cb9dA7fd52836842dD613": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x6b924862383b21e2EB5cD84c5cBCf0a68B284578": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x0c5870a76960042196272A1BaA8962bAda7709B0": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xdc66441f5A58Ad1Dd983bB0E3eb17eF8DF530383": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x7d0100f9363F65E8C79D35958A0a9bB350A9e5C8": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x05035636032E0458020c9984FA4A09900bD5e571": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xBE5bdEF944211892311722231d4Baf9ad5B34d5d": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xb3aeaF62DeFE5811A1d733D59099051f406AD5e5": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x40AA2B1819d174EE3Bd1737DdC8cA1E7B1ecc1a8": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x02023f74ED12Df7752144aE8A23411776D4698b4": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xfe3D81240Ff055d4193709bf870624142C055e33": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x939C76a579B1ECa4Cc5801105f0154457bf4139b": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xB32468a2D4a15A7c0553A7686988AdEC46cC5735": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x094c50583F8ef72220De3fDe64053eFADc69b66B": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xaf98d3E4fd33e642818FfCDDA1BCDFC2a37Ac04c": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x4BF57CaAaec5438C8F8F09AFC111594668913EbF": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xfa995A3A14280612304D80E60422416b8ADBAA6a": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xbd313d514ECd15a0224da4dE050C79E2CD8382aB": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x2ABd9A4D9CBbB5FcB1EB50677f27f5F5d8C84DcB": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x2A3229F33D41f1Dec2d20d6aD7D1d7776B38E74E": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xfA908C6EFc7C679451019e44825d511056E3f366": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xb3DD9be63653248A36319A7e2b1949CcF11863F7": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xD4De32547867B787ADEefaFd717100c03e859820": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0xEf2a6A9107Dc9d79360a241b81eaa4AD9b11F11A": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x4a9FFe19C382Fb745b29C9C4fa4628786EB57F11": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x357369234C8A61d80610E9B4eD03d7a906c30848": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x1fd2A56907B1db9B29c2D8F0037b6D4E104f5711": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x7d6fC8A28b2c0Fc35D2F27772a76982088234F3c": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 2830,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 2830,
  },
  "0x360f37e09c821985e9cD2518A646270C31445336": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x60f111E66b76F4e99037F413f4Bb2899e91C68D7": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x73d30ba3dc4fFD17C28cc2D75D12e50dF98f29CF": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xB0D0b6dA40e1B3aBCa12a38F5bD95793De935635": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x0C37a74fDFde0a862B5f27e4a0415AA32747fE97": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xF9a001d5B2c7c5e45693B41FCF931B94e680cAC4": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x1496294aDcC04F835994d5C80901C7D98061Cf16": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x57d4256b0Ae42816cf9F458fe4C3d8d52493FFc6": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x387149DFcedD188211F41EaFA71000a52527697f": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xaa7577a7A27aa7FcF6d0eC481B87Df3aD0F6a88E": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x26bcB16A88a9179eFaDc11b90e2a55ed8Bd74B6c": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x156668D884ea434CDc13c96B2C5B80d9f59FFdd5": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x31C683A67f0e3919a86fead9241d94f34679F11F": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xBDc930217dceEe7193CD908892f0CD1617077233": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xC99f40453B4EF2630261344220394740dA47BEab": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xe8Ec8a0821f12828e305DDbF01F09b16e0A06f88": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x52E701DD73339f598c35987a044Ea2865746a701": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xaB12275f2d91f87B301A4F01C9aF4E83b3F45Baa": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xC6ACE4d55c8bD5Ebd18b2866Aab7E64641756aA4": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x93B61c57C797003D096c612CEfAa47b20c35d926": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xe317C793ebc9d4A3732cA66e5a8fC4ffc213B989": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x1b5bbc00D336e42ce6dd79DDA4B5AB056A614f4b": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x00c511aB1B583F4eFAb3608D0897D377C4De47A6": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xAb1e16Dbd094C80278915E771595F2FDa2f196Ff": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xfDb64EeaDACf0e65B2cAcB278A3463449D80b6eA": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xB574A7Ab450EC8A5D0F22F76EfF021ad17BCC9F8": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x29c94fd33898B2D1AFA4a7Fc6df3077d4Be07c02": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x14d4DdA083196de7ea4aD7FA878353bc00Db0a20": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x83bA5d0264A03802c2428F3DBb8794f04aAAbb3B": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x7d19F17bd06853844b1F02138f4673778d17cED9": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x38B807A474553d3f5eb3E93B3927383fbe4Bb2A7": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xd4C3B2c29BB06483413a5A6d6D5CF3E22d04D349": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x8d0123637062f8c15FD6AFDe6F834C85f4AfeEDE": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xdBfd836c989E1FE9586cB0D1BFB35E7849Be23a5": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xa28e76149d03Aa311fef87c81bc0a26D5BF2098A": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x071D9fe61cE306AEF04b7889780f889f444D7BF7": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xAff1BF27f0aE7ce8AC0b7d3Ec7638933eDE0194E": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x1ca16bD078d03ef2Cc97dbD2f83855e272661414": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x57CA0d754888C1E825a66176629A95FB4Fc410d3": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xc0946011Aee46a6Fb3d81c7954Ff2EE8eDB8b436": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xaB3B6930A50d16cef578Bf4BE698743051D4d0C4": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xf565295EddCc0682bb16376c742E9Bc9dbB32512": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xD3bFCD76F8278a9e449AaeEC2cB2DFD2cFD215A5": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x2ab70c711e2235376772C4B18fa9d23aa59ff95A": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xE85d7eAeeFB07dA743c0b87994cACa5a7dFd5DdC": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x45d3730A8F811519f3CC310d54FFc4D2142b3773": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xcB8e41C7E926072e93Bc6fca3c39B2B8721ce6B6": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x22145532180494D08425b2c189338F9f982889e1": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x40Df2C223C9415b9a00085B8E48f319C24d39F16": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x2DceDA1886802c5E6165Be3687D7fFC79c3544E9": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x4C26B5b17CBb4aA1fDee5739c12f3D4459aAD539": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x01466B1c522a22e3a1B0F7D383c6deCb77101761": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x7d440D328595f8FB589a9E9ef9E6eF388A5Ee828": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x6b6cA48DA27304eeEb1D15d355D948f68b852733": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x4f9c6D4BF6E65Ba146886A09380DB91e9EB503C6": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x2810614e2719eb73dE47678942dad05C5CE98839": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x2Fa0244058a97E22f62a74DC26dB2a2B5E0311fd": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x3d7e23Af8DE6c01fd57A2C9b5fD1543004D9167F": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xf035Af8a3dFf1f90aeb21F9623589AE1330E9e09": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x1AA1381c01CAD97312B03e7085C4ab6c36861994": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x7e8100F91aD3780Cc71995194289BB8eB1b1ad7B": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xACd24aDdd3fD673d4E2462D3cd1E3d29305Ed1ba": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x7f314bE00306B7ce9B186F437574E52b5dFb384c": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xFC9926f779Acb36AC7b801DF8c5ffcc9A2d34D13": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xE8eE9B81c5f2cd6EAB7503E194BbA4cbf07a2bd1": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x7700946ddbcFd7D09FB9Db9D17CC32C2f3bd89CB": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xb393F86D7eaF3AAE2E9aD2F985E43E6B326f3E99": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x82e0bbB820749A55bee2bD8a2a674d25F6354113": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x31304cCDd28E62eF552824db08a350D752068c39": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x3EcDa1f750dC2A71b7a27fFA8B6b35ee5dbeFf0C": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x3E847015084B182DD47aaD0e95E005C51e1FE715": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x38aa2684781AD05D8B382BFA7c0C66Fa8EBCd4F5": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xfCb11F4f6749dd517aAA9f38035FECC1Fd91291d": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x0706c28186F6bB1349a2cA6ED75e2ec68E5F239e": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x140fC036D512b706fFAe8bE4b6625aE178035C9C": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x5fb681B8F186277Fceda57211b019e51C9983d51": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x1064aA646A7Aedbd40816Fc0C35E044D0244a764": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x18348ca858c6C642b110BAf0D807CCfa8752C3c4": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x9893E05C2A7a9BA3261c58710d297054D756007b": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xe6C18B1b4967b4c52F0E91CdeF9313c10365E7c7": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x9C8ac31625524c9e16Cd35B5e40B8e14552bf8E2": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x7D673c30A16cBD0F5c3eF954100502a07efBc445": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xF24eA6aDb290e4A3f5d0C5dA53d328e78dff2789": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xD3d665bD4ff72a6205611b2285D2d7f6453c3AC5": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x4dBd3F2FC7bDFfE2657F8916AD05eACA21344d38": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x50e7d5eaf135E49DecC6D33Ac1A780d257E57632": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xE5691DFc88e01c16F46560E1a5e6A5ebF0678BF9": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x385CE35599AE5e6F0EaF0f69841FffA9F41AcDd5": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x3B88CF2f5D5952cc54acD8605c012f1C3ECB1F03": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xDa2a6295A6223e15D2E1Bb3F03539bE74c376580": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x3f0FB61B79cEea17dcF7a7f38446485C2fC10ED1": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x4c7FF1EE095E0fa002D9DcA6c2a0597764c91d0D": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xe91D4C9B3a8E0b6696E8A2F460f6d8d9e1cc5615": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x82bbCaC5a8B81368A4A96f0265cb40E46020a1e1": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xA997baE9d3615dCFa152E42f0261a91b957839a3": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x2F82aBafa5E38975bB1fC4EEd06758cAA66e59e5": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x4Fab6f2ae14d38549cA0c5597369781bc004A7A9": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x9E4A9b4334F3167Bc7DD35f48f2238c73F532bAf": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xAf6376E68437ee82D1e0e47aE9C217F35681074c": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xb2eE8aF0D4435950380f67816Da29caA6fc76793": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x464a4cc807Aeec9EE71bbD1A510c2356Af351BeE": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x15Cae4eE29CA9A9c6A97a3EFCD373D6e2B0759bA": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x67daC7DCaDB37c7e208162B7131837a66B94e476": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xfdf6381F57b7FE98fe813045712f641B7E27C75c": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x1fbF4789Ac39De79936cCC29FA6789Db6848a275": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x3fbDF3e4aD2C661C8EC6067A69e339b1A730eb52": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x327A001Fc673Fa8a384C2ec024Cdb395671927EA": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xF3D9281fa183B74F32B96E1c5244596045f4edE8": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x7C7C9cE081BecB5F44eF9EEd3a90d99057d2EB53": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xFcc8819661b7120045786769C6E353a0cA972F2A": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xB95C88262f6632e4b7C94432B6A360C19e642E10": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x2CC8781EBb87D78ad45E84C57CcEB2F9756Cc4D1": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xE9547c9e5a6c2ce070612d96C3bAa9f681456766": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x1e40402FA7775101778001afbD6218ceD1C7628F": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x904da0251Eff2B67f65c8B2A82657636DdECCC94": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x85708c69bB709B1ec9a08617Ab99a68f93AF80De": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x77E3e957082Ca648c1C5b0F3e6AEc00Ab1245186": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x594D5BDd407b878C5df06bFa172B72D566806976": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xFb2DfABb0eeACb31F25e0080807e8980959a556f": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x934632319F5e60BA25680EeB152fb12EBC410441": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x570A3c9777c68b2E31DF8349c6BcCE6eF9D87111": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x8f01b4D3218dc7ca248184e81F58F8e49b4058F8": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xa2e118035CF4Cd6dd93C8832C024cCbe03E4E909": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xc49EB96cddaDEB4FA15D1a93c0cD65FbE62F5Dff": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x64163b7F5558C54C2494Fe18e09Ba8ee2ec7bC8a": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xB07e5dEc606e256815511D4cD11b5a65CA36AC01": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x0CE1D4fBbD736Cd3EEeB380B36Ee47AB31DA2D5e": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x9d9ADe3881F4Db9bc13298d2664B0eBE53E28c19": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x31cc7d6cD8A3c863eF090984D5908E194CF8F372": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xBd1df14002FAD5be95C04bce29517b03e7806891": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xfB9fC18028E4a89E3fBa6d8931F522efD4C385B9": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xA61186Ef4cc522028546cB79761C4c8Dd65c6C3B": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xD1E8fd37E062B2337Dac47e823115d3810F9F55a": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x735Be8799B3ffdb07484Fd492f930f930Da67ad5": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x3AC4C94282824141117168DcA8A9793bD103F952": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x9cc7474377Da9C8Fe6aa6BCa8494a74bC1A88433": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xD09A4B02d7D8Caf537aB36313214723b62B96810": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x683C3ac15e4E024E1509505B9a8F3f7B1A1cFf1e": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x9d7711Db9CdeAcB073d689E79c50c648820aC650": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xb258A4DbfDFFAA0e2A607BC3BB8e5AeF3022AFa7": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xB527E17Fe5E33FB00820B074C764ab70BC4A16eb": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x90B74eF7Bc1975BcB1c67450c7b1575922b67E54": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x72575bE6701185Da1253793b1AD2F9F3f0BdEA2f": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x533d5e63226Aab49C7BA3c3387ffA09e0bAf2F10": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xc86176dA1AE818281F8BfC5aFa03f9A092AA20A0": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x07b3a2BBfCd04a878F53b8C16e4997D3dfDe2d87": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x1cC3195C46C2F6AF665Ae281ad861eAb88ed8Fb5": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x185A430Af096CabcCA0710DE33713Dda7Be2aD18": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xAEbe89ea04E6154D6Cd0e4711783746260A6c3f0": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x0C599896cD30bF53507E136444fEaC8c3A2351Cd": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x931385cB7BEeEf891184215B7a92763181178617": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xEA630fBbBE263362198036CBD1671b56D581b3d2": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xF2c06f90FB58844C09220e01E3116A2293Df6960": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x52515c9b4DE4185F474140DA9F7C6eb8d443f05A": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xcdBF7A4dB0a22eE7A77a2402aA1Df67554dd5b32": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xdD84Ce1aDcb3A4908Db61A1dFA3353C3974c5a2B": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x3a111A913052Fc0C674406CD53947F4217FA778b": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xC821d3D24c27C713a9e2758086bD29B23f7381e2": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xdbC834afa93619758BCE5b25b9fE7b5d48632Ac2": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x7b0B5a326Aa4d95968e88654aF9b87624383A549": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x887299cF58A3f0BA1f6eE59122E19a65EefE2BB0": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xb1577413d0456d1A04C5d5978637759E84FDA356": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x34dB127f2A606a0795f3922FDD23083bB89FC27A": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x77182f4F182432945B520e054d3a7b5AB2a83e22": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x7Df70b612040c682d1cb2e32017446e230FcD747": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x95e67143304C5aF61Cb3eDE5FecF1D4beF5186c9": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xf598AaDF12a2FC4709b5Db0C4c169715EFAf2038": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x2fA57186D6e07E2F4c83e03808fD48833c2e5850": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xFe37dB16b98e571902a416D21D4c449981E2A2D5": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xE5d4924413ae59AE717358526bbe11BB4A5D76b9": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xC4b7bC6bbb6C94Db4E244130D3fA07D2586d92ec": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x0d1d74535bCAbda2dA2CfF5A53C2B899901D423B": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x8E8072A0a0465D681580600F0D2382372D2b9BF5": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xb6321C5E3D0A97B8d4218BC15Ec95bD8D1F7d639": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xa1870d3CaB6e9C4971e6286B2813CbC9E034E08c": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x5917cBF4D94dE1f6464a76845AB6cB26bA807Ed9": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x0d73AA5dE89B309798f9FcBbA8b86c4FD5f542fc": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xdeaa067E429Ad097465d8f3B50cA306f13061038": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xf4BdC18c46f742d1f48B84c889371F080cFD709c": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xeF291Dd546fF6ab5adf37844E08FaCA40b6A6e56": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x807EA4C5D7945dfEA05D358473FeE6042E92Cf37": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x01c12063B7b5B783A989A60aF9f9F06534307a2D": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x4F20CBb1149BE8839A3554189098b67c21BCb587": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x5e5C817E9264B46cBBB980198684Ad9d14f3e0B4": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x899eE6f1dc896c695918b6Fc169Ec43b84a3e74d": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x3AA228a80F50763045BDfc45012dA124Bd0a6809": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x7A6b049D83554dA1175d65be3f6264A74a05AE17": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x74a611163e4197d97c3FB71a84EA5164783396e8": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xfB8D679e87522b6A7EcC05BEE7FCBFAB764e9CCC": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x6DB3ebEdc8E67AfE3f8D3c3f001ff3D691182f21": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x11DeBf538C7D60252e47aC51e065c1dB2A524656": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x11d91151f54d4Edc390BA4Fc650aFdaEf4b676EE": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x67D404d7271EFC5f8176Dc03bD6Eb3610337987c": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xde02FFFb824720E25674a604BaF2277f21c66a0F": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xB7aA0a0047ab2c64478B6d8Cf8B6587BcAB5E11C": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x5ad68c5D28fCAF64067b8D5A3a555dA85b2c59E4": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x20c4Da23357bdcb705AfFC6c4E8948af77A3BF28": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x5AaAEF91F93bE4dE932b8e7324aBBF9f26DAa706": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xF5436eBc7c51D7bc1A2628B3D2284B2C66078686": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xfeB9eba35dc2e95338627Ea5b53E5c0450AA3E3E": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xC7D97B8F43823da80b5666F3016D525d696bE3Bd": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x03d0c10f8BDc400FfE6203e281650F1b4a9278E9": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xDdE5762845c5943Eb8e7D5c4136DF10Ef20E8CB3": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x5519D6b6d704503D434FaF563b39d3308238C363": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x96488F9fcd0C6997C771A26D85D1f61CaCfA502C": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x39f52180e71dbbAe7e1b03CbB557F2aC3e7aC8Df": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xbE69e095E738eb7260ad795C554ABa288e1f2ADc": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x3162323Cf5F49635575F283C53BBd37348A505BC": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xEBb404420279745D66bC5A06F30B4800e0cE0e0c": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xC330219F80328380a75dBBAca7f92f2F7976Bcb8": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x68D81Ee6b79AB50Abf1D921F45b8446393d7da8B": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x1E053743A6D9e482DB0d2Ca481fDE39b974C04D1": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x4015063FD711F4Abb52CAf7BEf210C96eff9701F": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xC27C64cde3635A30035817CC86B10D0104EB405E": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x8E1F7184C8145a466C99017e49c62f1b246b3FF3": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x78Cf6F557dDc7Fc11660Bc12DdBec777ab57F063": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x899CD70aD8f1b9bdFc9A179E7f6dcab6F9CaAB41": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xb8fca963D699D65A10187058476986D5ef457B0A": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x7AcE5CFfBf9e1B1A4E8F491A64013B0e6F774136": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x3ed41D6EF357aCfb38b1dCD1db3a6aeEB34e110b": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x4311b1D81BD6a7E81c412d6Cd2acA270e0673F8f": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xDA99f18E847d17A5cb72c48cdff1166080a27720": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x3C0B80cD813acbe499406d068a0736969e6091Af": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x9bd03673F9F3Db5037cD839E96675b047EEd143f": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x1cC7aB47441D69BE57c0a09bF44F92e3DfBd71eF": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xB35248FeEB246b850Fac690a1BEaF5130dC71894": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x1C2EA5a58D54914e06BEe9932F5ACd0a622930E8": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x68c8bC6577c091daC3EA75C26594Fd26F23E91f4": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x992B8C8deAB488C238D496e60764565b8c5C542f": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x715f280d07b7d98B00F7EEF9e4cA62E9C5dFF999": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x2a48C157bF6C29E08deeFA6f6a7E5041Ff2Ca9d9": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x5F1Ee29361206F1A129e808736F11598356c6031": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xfBD6AA5500a3ccD2A8311C8F2B1827ed314481d7": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x7c2ed77132d8fa523ED01C8900713A451B6c498F": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xB04aB323d08Db5Eb3e8b6b17AedDC6499BEdf40B": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xDfC772212ed7acB353842936cb2Ab7a48AaD8A8a": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x313a1D49F0C82E8D230941E4d61C3156351C4d69": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x38835098A8AcCD046Efa8123f9fCd5AAA2CB3a7E": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x50E01433711F0CdE93D09856405f64a579Fa5244": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xD42787bf70ca6c46BAD3cc8edf7De2e2524E6628": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xB97167440C95fD6df9053B813855d5bDCa557409": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xbC4BbD7A2ce9001CA18324602ac66733731656eD": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xc27FD9D5113dE19EA89D0265Be9FD93F35f052c8": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x2ddC53411256b4c1549B650Cf1ec6f5F4E655e18": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x578068C1E7B85336f425a002755Ef9aE97C9C77E": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x444113187164527ec0Fc6C541f6A248aCdCe9dD8": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x4e73643ed182F034d3F36AB39e77Dd83f4db1aC0": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xEB7a12fE169C98748EB20CE8286EAcCF4876643b": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xa5435aF060B0C3e55196Cf143738f536268235Ec": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x48117777554DA5B9e4fDCc0462DcF781EC4DAAA1": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x398ABecc8702a78878b3FbE8eE154C625d4eAd03": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x0CE47Fa9e0A105B9650cBc5fAcCeb10b76CE9920": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x7672BbB64595e5c2Ae3BC65FfE385Ee1ab8EA306": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x8eF9bA973B2EB977D46FD9Eed561Bc0D18f891d0": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x4ee12C95DEA425D7C1D8e3F0239416A128A68A68": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xbcc599587aa0c97f7CEEee9C3Bb2Db2b33F835e4": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xc2ddfe84756fA71c39E40AB6289B6889E355786b": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x0DA62cF0314A4053f6A0950E6d15dc8Fd40F0301": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x2e265eC28AcFa9a6Fe5a78735adC66E8b9D770D3": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x1ab9CC2ee65f1d4d3CeF63E6434869aD9ED3C4a1": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x70D3B5504401612639b6DADa5c88871bAD6F6Ff3": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xdF9941aE73A2e4264FbC3EeF4443D1fF4B29C999": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x30b07357285ce832d676DE355A0b82eC4B2c380B": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xfE6199108B7dc8Ed683d16d041358fF082309E31": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x2334Fd2C0010fB428724E31D8cfB0De030b3045C": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x2A2b31Ed1fC4b330Bc8Ef71c68E1B7013D4a23EE": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x5883DbC05A5B9E7620fd44eCD61466422Cf94918": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x8A7b802bbf5bB75dd1d491251a01EaE2646a5ad2": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x7e516e0cff9b6C190636e674BB0F781e1eD2170e": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x179abb9ecAe3352fC83678511Da5810902e338E5": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xe9e1456c2B908f04E5c0c21c288E5119769Bf6e9": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x855EA9A0ea1E2bd5b944d1D272aA336FFf80cB2E": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x9a858f8763d2b4Eb9846Be8AD7faB2e958AB777e": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x56431ce134259E4E2D579abdf608DE487F25Ab8C": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xbb6712A513C2d7F3E17A40d095a773c5d98574B2": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x7e5ccBf79f81baF0430a9eD8208580c7157F143C": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xe9034BcD20119ee7563d145Dc817820690afD5Fb": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xb1E079854268985431935ce53AA54C8e1722fA0D": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xdA7f23460ba023e10b7D1208A207c37Cd8f964BC": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x57524EfC73e194e4F19DC1606FB7a3E1Dbf719B3": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xf108a047F721c36369dcBA5137752Bab96C532fb": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xBA1BA61Ff159422bCb07F78017186624e94E6936": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x6a0Aeb7FFB72657387097Aa2c3eECB28b898Cf8e": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x5d0D47F2Eea897C2C56F79fF18CE0E3Eb24bDd71": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xd16749Fc8Bf3990198b76300aCebFedB6D6db37d": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x8f2B2dE4aD97D901E53b7d07e8410FBd5cF94937": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x36f8A9eBb4479B1b877920b4cE0a11Ee25C29a8b": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x1dD882200CC37da7774f1998564241C80fBFCB6E": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x376CB504bDC1f1046B343C24395da72f77d630a2": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x0dA47666a8311250C7Fa713250A828307fe933fE": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xd20fc3c85010B39726250d56ed4EF7e3dB87f484": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x0E83AeF0E8141281d8596eF7CD6ca05aD6729f2c": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xD9fa432ec7B4EC49478Ca81590AC833C7672bE8E": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x3679AaC6fFe31058bc24DAcE86853F166a74DFDD": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xB855c4eBb5b6eB3F2033aecC4E543e27BC39465D": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x04e97F8ea039B04474192d202d6412075dA31275": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0xD491E849e7cfC69f4bFfbE99d3F453C4528c3C73": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
  "0x47d4dA26eCb6BfA2d76b2B92794ffb67FB6F9165": {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 2500,
    miladySnxShare: 0,
    totalShare: 2500,
  },
};

export default AIRDROPS;

import AIRDROPS from "../data/airdrops";
import { StandardMerkleTree } from "@openzeppelin/merkle-tree";

const generateProof = (address: string): string[] => {
  const sortedSnapshot = Object.entries(AIRDROPS).sort(([a], [b]) =>
    a.toLowerCase().localeCompare(b.toLowerCase())
  );
  const index = sortedSnapshot.findIndex(([a]) => a === address);
  const merkleTreeData = sortedSnapshot.map(([address, data]) => [
    address,
    BigInt(data.totalShare) * BigInt(10) ** BigInt(18),
  ]);
  const tree = StandardMerkleTree.of(merkleTreeData, ["address", "uint256"]);
  return tree.getProof(index);
};

export default generateProof;

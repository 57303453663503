import { useEthers } from "@usedapp/core";
import AIRDROPS, { AirdropData } from "../../data/airdrops";

const useAirdropData = (): AirdropData | null => {
  const { account } = useEthers();
  if (!account) return null;
  const airdropData = AIRDROPS[account];
  if (airdropData) return airdropData;
  return {
    liquidationPoints: 0,
    liquidationPointsShare: 0,
    votingShare: 0,
    registrationShare: 0,
    miladyBlurShare: 0,
    miladySnxShare: 0,
    totalShare: 0,
  };
};

export default useAirdropData;

import { useEthers } from "@usedapp/core";
import useAirdrop from "../contracts/use-airdrop";
import { useEffect, useState } from "react";
import useBlock from "../utils/use-block";
import { useDispatch } from "react-redux";
import useSupportedChain from "../utils/use-supported-chain";
import { setWarning } from "../../../state/uiSlice";

const useHasClaimedAirdrop = (): boolean | null => {
  const dispatch = useDispatch();
  const { account } = useEthers();
  const airdropContract = useAirdrop();
  const supportedChain = useSupportedChain();
  const [hasClaimed, setHasClaimed] = useState<boolean | null>(null);
  const block = useBlock();

  useEffect(() => {
    if (!account) return;
    if (!airdropContract) return;
    if (!supportedChain) return;

    try {
      airdropContract
        .hasClaimed(account)
        .then((res: boolean) => setHasClaimed(res));
    } catch (e: any) {
      console.log(e);
      dispatch(setWarning("Error fetching claimed airdrop status"));
    }
  }, [account, block, airdropContract, dispatch, supportedChain]);

  return hasClaimed;
};

export default useHasClaimedAirdrop;

import styled from "styled-components";

import { useState } from "react";

import { CardFrame } from "../../styles/Frames";

import susd from "../../assets/ui/susd.svg";
import external from "../../assets/ui/external-pink.svg";
import { BONDING_DOCS } from "../../app/constants/urls";
import { BONDING_ADDRESS } from "../../app/constants/addresses";
import useTlxPrice from "../../app/web3/views/use-tlx-price";
import { ScaledNumber } from "scaled-number";
import { Splitter } from "../../styles/utils";
import InputHeader from "../../components/InputHeader";
import Input from "../../components/Input";
import Button from "../../components/Button";
import AssetSelector from "../../components/AssetSelector";
import useLeveragedTokenData from "../../app/web3/views/use-leveraged-token-data";
import getLeveragedTokenName from "../../app/helpers/get-leveraged-token-name";
import useBondingExchangeRate from "../../app/web3/views/use-bonding-exchange-rate";
import useBondingAvailableTlx from "../../app/web3/views/use-bonding-available-tlx";
import useLeveragedToken from "../../app/web3/contracts/use-leveraged-token";
import useApprovedAmount from "../../app/web3/views/use-approved-amount";
import getRoundedScaledNumber from "../../app/helpers/get-rounded-scaled-number";
import useBoding from "../../app/web3/contracts/use-bonding";
import { useDispatch } from "react-redux";
import { setError } from "../../state/uiSlice";
import tlxIcon from "../../assets/tokens/st-tlx.png";
import round from "../../app/helpers/round";
import getSlippage from "../../app/helpers/get-slippage";
import Tooltip from "../../components/Tooltip";
import { useNavigate } from "react-router-dom";
import { STAKE_PATH } from "../../app/constants/paths";
import InfoBox from "../../components/InfoBox";
import warningIcon from "../../assets/greeble/warning.svg";
import Seo from "../../components/Seo";
import getLeveragedTokenIcon from "../../app/helpers/get-leveraged-token-icon";
// import bondIcon from "../../assets/greeble/bond.svg";

const StyledBondPage = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  padding: 4rem;

  @media (max-width: 900px) {
    padding: 2rem;
  }
`;

const Container = styled(CardFrame)`
  display: flex;
  width: 100%;
  padding: 4rem;
  max-width: 75rem;

  > div:first-child {
    margin-right: 4rem;
  }

  @media (max-width: 900px) {
    padding: 2rem;
    flex-direction: column;

    > div:first-child {
      margin-right: none;
    }
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TokenName = styled.div`
  font-size: 2.4rem;
  font-weight: 400;
  margin-left: 0.8rem;
`;

const Header = styled.div`
  font-size: 2.4rem;
  font-weight: 300;
  color: var(--sub);
  margin-bottom: 0.4rem;
`;

const Percent = styled.div<{ $positive: boolean }>`
  font-size: 3.2rem;
  font-weight: 400;
  font-size: 4rem;
  font-weight: 300;
  color: ${({ $positive }) => ($positive ? "var(--success)" : "var(--error)")};
  margin-bottom: 4rem;
`;

const StatRow = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 4rem;
`;

const AmoutOutRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AmoutOutSection = styled.div`
  display: flex;
  align-items: center;
`;

const RowColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const LargeIcon = styled.img`
  height: 5rem;
  border-radius: 50%;
`;

const StatHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem;
`;

const StatHeader = styled.div`
  font-size: 1.6rem;
  font-weight: 400;
  white-space: nowrap;
`;

const StateValueContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem;
`;

const StatValue = styled.div`
  font-size: 2.4rem;
  font-weight: 300;
`;

const StatSusdIcon = styled.img`
  height: 2rem;
  margin-left: 0.6rem;
`;

const DocsContainer = styled.div`
  display: flex;

  @media (max-width: 900px) {
    display: none;
  }
`;

const DocsText = styled.span`
  font-size: 1.2rem;
  font-weight: 400;
`;

const DocsLink = styled.a`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 400;
  color: var(--primary);
  margin-left: 0.4rem;
  cursor: pointer;
`;

const ExternalIcon = styled.img`
  height: 1.2rem;
  margin-left: 0.2rem;
`;

const BondForm = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1;
  grid-gap: 2.4rem;
`;

const BondPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tlxPrice = useTlxPrice();
  const bondingContract = useBoding();
  const bondingExchangeRate = useBondingExchangeRate();
  const bondingAvailableTlx = useBondingAvailableTlx();
  const leveragedTokenDataSource = useLeveragedTokenData();
  const leveragedTokenData = leveragedTokenDataSource
    ?.filter((data) => data.isActive && !data.exchangeRate.isZero())
    .sort((a, b) => {
      return (
        b.userBalance.mul(b.exchangeRate).toNumber() -
        a.userBalance.mul(a.exchangeRate).toNumber()
      );
    });
  const portfolioValue = leveragedTokenData?.reduce((acc, token) => {
    return acc.add(token.userBalance.mul(token.exchangeRate));
  }, ScaledNumber.fromUnscaled(0));

  const [amount, setAmount] = useState("");
  const startLeveragedToken =
    leveragedTokenData && leveragedTokenData.length > 0
      ? leveragedTokenData[0].addr
      : "";
  const [leveragedTokenAddress, setLeveragedTokenAddress] =
    useState(startLeveragedToken);
  const selectedLeveragedToken = leveragedTokenData?.find(
    (data) => data.addr === leveragedTokenAddress
  );
  const leveragedToken = useLeveragedToken(leveragedTokenAddress);
  const approvedAmount = useApprovedAmount(
    leveragedTokenAddress,
    BONDING_ADDRESS
  );

  const bondingTlxPrice = bondingExchangeRate
    ? ScaledNumber.fromUnscaled(1).div(bondingExchangeRate)
    : null;
  const bondingDiscount =
    bondingTlxPrice && tlxPrice
      ? tlxPrice.sub(bondingTlxPrice).div(tlxPrice)
      : null;
  const maxBondAmount =
    bondingAvailableTlx && bondingExchangeRate
      ? bondingAvailableTlx.div(bondingExchangeRate).div(1e18).mul(1e18) // Rounding to 0 DP
      : null;
  const bondAmount =
    amount && selectedLeveragedToken
      ? getRoundedScaledNumber(amount, selectedLeveragedToken.userBalance)
      : null;
  const approved =
    approvedAmount && bondAmount && approvedAmount.gte(bondAmount);
  const expectedStTlxAmount =
    bondAmount && bondingExchangeRate && selectedLeveragedToken
      ? bondAmount
          .mul(selectedLeveragedToken.exchangeRate)
          .mul(bondingExchangeRate)
      : null;

  return (
    <StyledBondPage>
      <Seo
        title="Bond Leveraged Tokens"
        description="Bond Leveraged Tokens to receive stTLX at a discount."
      />
      <Container>
        <Column>
          {/* <InfoBox
            icon={bondIcon}
            text="Bond leveraged tokens for stTLX. Discount increases until the next user bonds."
          /> */}
          <Header>Bonding Discount</Header>
          <Percent
            $positive={bondingDiscount ? !bondingDiscount.isNegative() : true}
          >
            {bondingDiscount
              ? `${Math.round(bondingDiscount.toNumber() * 100)}%`
              : "--%"}
          </Percent>
          <StatRow>
            <RowColumn>
              <StatHeaderContainer>
                <StatHeader>Max Bondable</StatHeader>
                <Tooltip>
                  The maximum amount you can bond in sUSD at the current
                  discount. The bonding discount decreases each time a user
                  bonds
                </Tooltip>
              </StatHeaderContainer>
              <StateValueContainer>
                <StatValue>
                  {maxBondAmount ? maxBondAmount.toCryptoString() : "---"}
                </StatValue>
                <StatSusdIcon src={susd} alt="sUSD" />
              </StateValueContainer>
            </RowColumn>
            <RowColumn>
              <StatHeaderContainer>
                <StatHeader>Portfolio Value</StatHeader>
                <Tooltip>
                  The total value in sUSD of your leveraged token portfolio
                </Tooltip>
              </StatHeaderContainer>
              <StateValueContainer>
                <StatValue>
                  {portfolioValue
                    ? round(portfolioValue.toNumber(), 2).toLocaleString()
                    : "---"}
                </StatValue>
                <StatSusdIcon src={susd} alt="sUSD" />
              </StateValueContainer>
            </RowColumn>
          </StatRow>
          <DocsContainer>
            <DocsText>Learn about bonding in the </DocsText>
            <DocsLink href={BONDING_DOCS} target="_blank">
              Docs <ExternalIcon src={external} alt="external link icon" />
            </DocsLink>
          </DocsContainer>
        </Column>
        <Column>
          <BondForm>
            <AssetSelector
              active={leveragedTokenAddress}
              setActive={setLeveragedTokenAddress}
              options={
                leveragedTokenData?.map((data) => {
                  return {
                    id: data.addr,
                    icon: getLeveragedTokenIcon(data.symbol),
                    name: getLeveragedTokenName(
                      data.targetAsset,
                      data.targetLeverage.toNumber(),
                      data.isLong
                    ),
                    symbol: data.symbol,
                    volume: data.userBalance.toNumber(),
                    coingeckoId: "ethereum",
                    color: "red",
                    balance: data.userBalance,
                    price: data.exchangeRate,
                  };
                }) || []
              }
            />
            {bondingDiscount && bondingDiscount.isNegative() && !!amount && (
              <InfoBox
                warning
                text="The bonding discount is currently negative. Consider waiting for a positive discount."
                icon={warningIcon}
              />
            )}
            <Splitter>
              <InputHeader
                header={`${selectedLeveragedToken?.symbol || "-----"} Amount`}
                tooltip={`The amount of ${
                  selectedLeveragedToken?.symbol || "-----"
                } you would like to bond`}
              />
              <Input
                number
                value={amount}
                setValue={(value: string) => setAmount(value)}
                placeholder={`Enter ${
                  selectedLeveragedToken?.symbol || "-----"
                } amount`}
                max={selectedLeveragedToken?.userBalance.toNumber()}
                price={
                  selectedLeveragedToken
                    ? selectedLeveragedToken.exchangeRate.toNumber()
                    : 0
                }
                error={
                  selectedLeveragedToken &&
                  bondAmount &&
                  maxBondAmount &&
                  bondAmount
                    .mul(selectedLeveragedToken.exchangeRate)
                    .gt(maxBondAmount)
                    ? "Amount exceeds max bondable"
                    : ""
                }
              />
            </Splitter>
            <AmoutOutRow>
              <AmoutOutSection>
                <LargeIcon src={tlxIcon} alt="TLX Icon" />
                <TokenName>stTLX</TokenName>
              </AmoutOutSection>
              <TokenName>
                {expectedStTlxAmount ? expectedStTlxAmount.toCryptoString() : 0}
              </TokenName>
            </AmoutOutRow>
            <Button
              web3
              wide
              primary={!!amount}
              disabled={
                !amount || amount === "0" || !bondAmount || bondAmount.isZero()
              }
              action={async () => {
                if (!leveragedToken) return;
                if (!bondingContract) return;
                if (!bondAmount) return;
                if (!expectedStTlxAmount) return;
                if (!selectedLeveragedToken) return;

                if (!approved) {
                  try {
                    const tx = await leveragedToken.approve(
                      BONDING_ADDRESS,
                      bondAmount.toPlain().value
                    );
                    await tx.wait();
                  } catch (e: any) {
                    dispatch(
                      setError({
                        message: e.message,
                        source: "Bond/Approve",
                      })
                    );
                  }
                  return;
                }

                try {
                  const tx = await bondingContract.bond(
                    leveragedTokenAddress,
                    bondAmount.toPlain().value,
                    expectedStTlxAmount
                      .mul(
                        (
                          1 -
                          getSlippage(
                            selectedLeveragedToken.targetLeverage.toNumber()
                          )
                        ).toString()
                      )
                      .toPlain().value
                  );
                  await tx.wait();
                  navigate(`/${STAKE_PATH}`);
                } catch (e: any) {
                  dispatch(
                    setError({
                      message: e.message,
                      source: "Bond/Bond",
                    })
                  );
                }
                setAmount("");
              }}
            >
              {selectedLeveragedToken
                ? !!amount
                  ? approved
                    ? `Bond ${bondAmount.toCryptoString()} ${
                        selectedLeveragedToken.symbol
                      }`
                    : "Approve Bonding"
                  : "Enter an amount"
                : "Select a token"}
            </Button>
          </BondForm>
        </Column>
      </Container>
    </StyledBondPage>
  );
};

export default BondPage;

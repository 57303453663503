import { useEffect, useState } from "react";
import { ScaledNumber } from "scaled-number";
import useBlock from "../utils/use-block";
import { useDispatch } from "react-redux";
import useSupportedChain from "../utils/use-supported-chain";
import useBoding from "../contracts/use-bonding";
import { setWarning } from "../../../state/uiSlice";

const useBondingAvailableTlx = (): ScaledNumber | null => {
  const dispatch = useDispatch();
  const supportedChain = useSupportedChain();
  const [amount, setAmount] = useState<ScaledNumber | null>(null);
  const block = useBlock();
  const bonding = useBoding();

  useEffect(() => {
    if (!bonding) return;
    if (!supportedChain) return;

    const getAmount = async () => {
      try {
        const availableTlx = await bonding.availableTlx();

        setAmount(new ScaledNumber(availableTlx));
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning("Error fetching available TLX for bonding"));
      }
    };

    getAmount();
  }, [block, supportedChain, bonding, dispatch]);

  return amount;
};

export default useBondingAvailableTlx;

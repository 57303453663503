import styled from "styled-components";
import InfoBox from "../../components/InfoBox";

import checkSquare from "../../assets/greeble/check-square.svg";
import { useState } from "react";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { TLX_ADDRESS } from "../../app/constants/addresses";
import useTokenBalance from "../../app/web3/views/use-token-balance";
import useLocker from "../../app/web3/contracts/use-locker";
import useApprovedAmount from "../../app/web3/views/use-approved-amount";
import useToken from "../../app/web3/contracts/use-token";
import { useDispatch } from "react-redux";
import { setError } from "../../state/uiSlice";
import getRoundedScaledNumber from "../../app/helpers/get-rounded-scaled-number";
import InputHeader from "../../components/InputHeader";
import { Splitter } from "../../styles/utils";

const StyledLock = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1;
  grid-gap: 2.4rem;
  margin-top: 2.4rem;
`;

const Lock = () => {
  const dispatch = useDispatch();
  const tlxBalance = useTokenBalance(TLX_ADDRESS);
  const lockerContract = useLocker();
  const tlxContract = useToken(TLX_ADDRESS);
  const approvedAmount = useApprovedAmount(
    TLX_ADDRESS,
    lockerContract?.address
  );

  const [amount, setAmount] = useState("");

  const lockAmount = getRoundedScaledNumber(amount, tlxBalance);
  const approved = approvedAmount && approvedAmount.gte(lockAmount);

  return (
    <StyledLock>
      <InfoBox
        icon={checkSquare}
        text="Attain governance rights & earn additional TLX by locking your TLX for 26 weeks"
      />
      <Splitter>
        <InputHeader
          header="TLX Amount"
          tooltip="The amount of TLX you would like to lock in the locker"
        />
        <Input
          number
          value={amount}
          setValue={(value: string) => setAmount(value)}
          placeholder="Enter TLX amount"
          max={tlxBalance ? tlxBalance.toNumber() : 0}
        />
      </Splitter>
      <Button
        web3
        wide
        primary
        disabled={lockAmount.isZero()}
        action={async () => {
          if (!lockerContract) return;
          if (!tlxContract) return;

          if (!approved) {
            try {
              const tx = await tlxContract.approve(
                lockerContract.address,
                lockAmount.value
              );
              await tx.wait();
            } catch (e: any) {
              dispatch(
                setError({
                  message: e.message,
                  source: "Lock/Approve",
                })
              );
            }
            return;
          }

          try {
            const tx = await lockerContract.lock(lockAmount.value);
            await tx.wait();
          } catch (e: any) {
            dispatch(
              setError({
                message: e.message,
                source: "Lock/Lock",
              })
            );
          }
          setAmount("");
        }}
      >
        {lockAmount.isZero()
          ? "Enter an amount"
          : !approved
          ? "Approve Locker"
          : `Lock ${lockAmount.toCryptoString()} TLX`}
      </Button>
    </StyledLock>
  );
};

export default Lock;

import styled from "styled-components";
import Popup from "../../components/Popup";
import InfoSection from "../../components/InfoSection";

import external from "../../assets/ui/external-pink.svg";
import { AIRDROP_INFO } from "../../app/constants/urls";
import Button from "../../components/Button";
import useAirdrop from "../../app/web3/contracts/use-airdrop";
import { useNavigate } from "react-router-dom";
import { LOCK_PATH } from "../../app/constants/paths";
import useAirdropData from "../../app/web3/views/use-airdrop-data";
import generateProof from "../../app/helpers/generate-proof";
import { useEthers } from "@usedapp/core";
import { useDispatch } from "react-redux";
import { setError } from "../../state/uiSlice";
import useLockerApr from "../../app/web3/views/use-locker-apr";

const StyledAirdrop = styled.div`
  width: 40rem;
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const SubHeader = styled.div`
  font-size: 1.6rem;
  font-weight: 300;
  color: var(--sub);
  margin-bottom: 3rem;

  @media (max-width: 900px) {
    font-size: 1.4rem;
    margin-bottom: 2rem;
  }
`;

const Link = styled.a`
  font-size: 1.6rem;
  font-weight: 300;
  color: var(--primary);
  cursor: pointer;

  @media (max-width: 900px) {
    font-size: 1.4rem;
  }
`;

const ExternalIcon = styled.img`
  height: 1.2rem;
`;

const ButtonConatiner = styled.div`
  margin-top: 2rem;
`;

interface Props {
  open: boolean;
  close: () => void;
}

const Airdrop = ({ open, close }: Props) => {
  const dispatch = useDispatch();
  const { account } = useEthers();
  const airdropContract = useAirdrop();
  const navigate = useNavigate();
  const apr = useLockerApr();
  const airdropData = useAirdropData();

  return (
    <Popup open={open} close={close} header="Claim Airdrop">
      <StyledAirdrop>
        <SubHeader>
          Claim your airdrop to receive your TLX tokens. Lock your TLX tokens to
          participate in governance and receive additional TLX rewards.{" "}
          <Link href={AIRDROP_INFO} target="_blank">
            Learn more <ExternalIcon src={external} alt="External Icon" />
          </Link>
        </SubHeader>
        <InfoSection
          lines={[
            {
              label: "Claiming Liquidation Points",
              tooltip:
                "TLX Airdrop earned from liquidations on Synthetix perps",
              value: airdropData
                ? airdropData.liquidationPointsShare.toLocaleString()
                : "---",
            },
            {
              label: "Council Voting",
              tooltip:
                "TLX Airdrop earned from voting on the TLX ecosystem council vote",
              value: airdropData
                ? airdropData.votingShare.toLocaleString()
                : "---",
            },
            {
              label: "Registration",
              tooltip:
                "TLX Airdrop earned from registering during the registration period",
              value: airdropData
                ? airdropData.registrationShare.toLocaleString()
                : "---",
            },
            {
              label: "Milady Blur Liquidations",
              tooltip:
                "TLX Airdrop earned from getting liquidated borrowing against your Milady on Blur",
              value: airdropData
                ? airdropData.miladyBlurShare.toLocaleString()
                : "---",
            },
            {
              label: "Milady Synthetix Liquidations",
              tooltip:
                "TLX Airdrop earned from getting liquidated on Synthetix and holding a Milady",
              value: airdropData
                ? airdropData.miladySnxShare.toLocaleString()
                : "---",
            },
          ]}
        />
        <ButtonConatiner>
          <Button
            web3
            wide
            loading={!airdropContract}
            action={async () => {
              if (!airdropContract) return;
              if (!airdropData) return;
              if (!account) return;

              try {
                const tx = await airdropContract.claim(
                  BigInt(airdropData.totalShare) * BigInt(1e18),
                  generateProof(account)
                );
                await tx.wait();
              } catch (e: any) {
                dispatch(
                  setError({
                    message: e.message,
                    source: "Airdrop/Claim",
                  })
                );
              }
              navigate(`/${LOCK_PATH}`);
            }}
          >
            {`Claim ${
              airdropData ? airdropData.totalShare.toLocaleString() : "---"
            } TLX`}
          </Button>
        </ButtonConatiner>
        <ButtonConatiner>
          <Button
            web3
            wide
            primary
            loading={!airdropContract}
            action={async () => {
              if (!airdropContract) return;
              if (!airdropData) return;
              if (!account) return;

              try {
                const tx = await airdropContract.claim(
                  BigInt(airdropData.totalShare) * BigInt(1e18),
                  generateProof(account)
                );
                await tx.wait();
              } catch (e: any) {
                dispatch(
                  setError({
                    message: e.message,
                    source: "Airdrop/Claim",
                  })
                );
              }
              navigate(`/${LOCK_PATH}`);
            }}
          >
            {`Claim ${
              airdropData ? airdropData.totalShare.toLocaleString() : "---"
            } TLX & Lock for ${apr ? apr.toPercent() : "--%"} APR`}
          </Button>
        </ButtonConatiner>
      </StyledAirdrop>
    </Popup>
  );
};

export default Airdrop;

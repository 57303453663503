const getCountdownText = (time: Date) => {
  const now = new Date();
  const timeRemaining = time.getTime() - now.getTime();

  const DAY = 1000 * 60 * 60 * 24;
  const weeks = Math.floor(timeRemaining / (DAY * 7));
  const days = Math.floor((timeRemaining - weeks * DAY * 7) / DAY);
  const hours = Math.floor(
    (timeRemaining - weeks * DAY * 7 - days * DAY) / (1000 * 60 * 60)
  );

  let unlockText = "";
  if (weeks > 0) unlockText += ` ${weeks} week${weeks > 1 ? "s" : ""},`;
  if (days > 0) unlockText += ` ${days} day${days > 1 ? "s" : ""},`;
  if (hours > 0) unlockText += ` ${hours} hour${hours > 1 ? "s" : ""}`;
  unlockText += ".";
  unlockText = unlockText.replace(/,([^,]*)$/, " and$1");
  unlockText = unlockText.replace(" and.", ".");
  unlockText = unlockText.replace(".", "");
  unlockText = unlockText.trim();

  return unlockText;
};

export default getCountdownText;

export const SUPPORTED_LEVERAGE: Record<string, number[]> = {
  // Blue Chip
  ETH: [1, 2, 3, 4, 5, 7],
  SOL: [1, 2, 3, 4, 5],
  BTC: [1, 2, 3, 4, 5, 7],

  // Long Tail
  LINK: [1, 2, 5],
  OP: [1, 2, 5],
  PEPE: [1, 2, 5],
  DOGE: [2, 5],
  ETHBTC: [2, 5],
  SUI: [2, 5],
};

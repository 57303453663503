import styled from "styled-components";
import InfoBox from "../../components/InfoBox";

import timer from "../../assets/greeble/timer.svg";
import checkSquare from "../../assets/greeble/check-square.svg";
import useLockerData from "../../app/web3/views/use-locker-data";
import { ScaledNumber } from "scaled-number";
import getCountdownText from "../../app/helpers/get-countdown-text";
import LoadingSpinner from "../../components/LoadingSpinner";

const StyledUnlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.4rem;
`;

const Unlock = () => {
  const lockerData = useLockerData();

  return (
    <StyledUnlock>
      <LoadingSpinner show={!lockerData} />
      {lockerData && lockerData.userLocked.gt(ScaledNumber.fromUnscaled(0)) && (
        <InfoBox
          primary
          icon={timer}
          text={`Your TLX tokens will unlock in ${getCountdownText(
            lockerData.unlockTime
          )}`}
        />
      )}
      {lockerData && lockerData.userLocked.eq(ScaledNumber.fromUnscaled(0)) && (
        <InfoBox
          icon={checkSquare}
          text="No TLX locked. Lock TLX to earn TLX rewards."
        />
      )}
    </StyledUnlock>
  );
};

export default Unlock;

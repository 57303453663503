import { useEthers } from "@usedapp/core";
import { useEffect, useState } from "react";
import { ScaledNumber } from "scaled-number";
import useBlock from "../utils/use-block";
import useVesting from "../contracts/use-vesting";
import { useDispatch } from "react-redux";
import useSupportedChain from "../utils/use-supported-chain";
import { setWarning } from "../../../state/uiSlice";
import useMulticall from "./use-multicall";

export interface VestingDataType {
  allocated: ScaledNumber;
  vested: ScaledNumber;
  vesting: ScaledNumber;
  claimed: ScaledNumber;
  claimable: ScaledNumber;
}

const useVestingData = (): VestingDataType | null => {
  const dispatch = useDispatch();
  const { account } = useEthers();
  const vestingContract = useVesting();
  const supportedChain = useSupportedChain();
  const [vestingData, setVestingData] = useState<VestingDataType | null>(null);
  const block = useBlock();
  const multicall = useMulticall();

  useEffect(() => {
    if (!account) return;
    if (!vestingContract) return;
    if (!supportedChain) return;

    const getVestingData = async () => {
      try {
        const [allocated, vested, vesting, claimed, claimable] =
          await multicall([
            vestingContract.multi.allocated(account),
            vestingContract.multi.vested(account),
            vestingContract.multi.vesting(account),
            vestingContract.multi.claimed(account),
            vestingContract.multi.claimable(account),
          ]);

        setVestingData({
          allocated: new ScaledNumber(allocated, 18),
          vested: new ScaledNumber(vested, 18),
          vesting: new ScaledNumber(vesting, 18),
          claimed: new ScaledNumber(claimed, 18),
          claimable: new ScaledNumber(claimable, 18),
        });
      } catch (e: any) {
        console.log(e);
        dispatch(setWarning("Error fetching vesting data"));
      }
    };

    getVestingData();
  }, [account, block, vestingContract, supportedChain, dispatch, multicall]);

  return vestingData;
};

export default useVestingData;
